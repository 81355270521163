/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';
import C007 from './C007';
import ovalImg from '../../../../../assets/oval.png';

export default function P007(props) {
  return (
    <div className={`p007 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="0px 428fr 0px"
        y="120fr minmax(0px, max-content) 120fr"
        className={css(styles.flex)}
      >
        <div className={css(styles.flex_row)}>
          <div className={css(styles.flex1, styles.flex1_layout)}>
            <h1 className={css(styles.public1, styles.public1_layout)}>
              {props.public1}
            </h1>
            <div className={css(styles.flex1_col)}>
              <Px.div
                x="0px 83fr 0px"
                y="45fr minmax(0px, max-content) 1px"
                className={css(styles.flex)}
              >
                <h4 className={css(styles.public2, styles.public2_layout)}>
                  {props.public2}
                  <div className={css(styles.line)} />
                </h4>
              </Px.div>
            </div>
          </div>
        </div>
        <div className={css(styles.carousel)}>
          {props.carousel}
          <div
            style={{
              position: 'absolute',
              top: -20,
              left: 0
            }}
          >
            <Px.img
              data-src={ovalImg}
              x="8.27px 87.87fr 331.85fr"
              y="0 minmax(0px, max-content) 542fr"
              className={`${css(styles.decorator)} swiper-lazy`}
            />
          </div>
        </div>
        <div className={css(styles.flex_row2)}>
          <div className={css(styles.flex3, styles.flex3_layout)}>
            <div className={css(styles.flex3_spacer1)} />
            <Px.div
              x="0px 180fr 0px"
              y="1fr minmax(0px, 60fr) 1fr"
              className={css(styles.content_box)}
            >
              <C007
                image={props.icon1}
                paragraph_body={props.info1}
              />
            </Px.div>
            <div className={css(styles.flex3_spacer1)} />
            <Px.div
              x="0px 180fr 0px"
              y="1fr minmax(0px, 60fr) 1fr"
              className={css(styles.content_box)}
            >
              <C007
                image={props.icon2}
                paragraph_body={props.info2}
              />
            </Px.div>
            <div className={css(styles.flex3_spacer2)} />
            <Px.div
              x="0px 180fr 0px"
              y="1fr minmax(0px, 60fr) 1fr"
              className={css(styles.content_box)}
            >
              <C007
                image={props.icon3}
                paragraph_body={props.info3}
              />
            </Px.div>
            <div className={css(styles.flex3_spacer1)} />
          </div>
        </div>
      </Px.div>
    </div>
  );
}

P007.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'visible',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  flex_spacer: {
    display: 'flex',
    flex: '1 1 71px'
  },
  flex_row: {
    display: 'flex'
  },
  flex1: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    width: '92.06%',
    minWidth: 'min-content',
    margin: '0px auto',
    '@media (max-width: 575.98px)': {
      width: '92.06%',
      minWidth: 'min-content',
      margin: '5px auto'
    }
  },
  public1: {
    display: 'flex',
    alignItems: 'flex-end',
    font: '100 60px "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '2.5px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      fontSize: '60px',
      textAlign: 'left'
    }
  },
  public1_layout: {
    position: 'relative',
    margin: 0
  },
  public2: {
    display: 'flex',
    justifyContent: 'flex-end',
    font: '18px/1.2 "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'right',
    letterSpacing: '1px',
    paddingBottom: '10px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '16px',
      textAlign: 'left'
    }
  },
  public2_layout: {
    position: 'relative',
    margin: 0
  },
  line: {
    position: 'absolute',
    bottom: '10px',
    left: 0,
    width: '100%',
    height: '1px',
    background: '#000'
  },
  carousel: {
    position: 'relative',
    width: '100%',
    paddingTop: '80%',
    marginTop: '20px'
  },
  flex_row2: {
    display: 'flex',
    flex: '1 1 262px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flex3: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  flex3_layout: {
    position: 'relative',
    overflow: 'visible',
    width: 'fit-content',
    maxWidth: '80%',
    height: 'calc(100%)'
  },
  flex3_spacer: {
    display: 'flex',
    flex: '1 1 auto'
  },
  content_box: {
    position: 'relative',
    width: '100%',
    height: '100%',
    flex: '1 1 auto'
  },
  flex3_spacer1: {
    display: 'flex',
    flex: '1 1 5px'
  },
  flex3_spacer2: {
    display: 'flex',
    flex: '1 1 5px'
  },
  decorator: {
    width: '100%',
    height: 'auto'
  }
});

P007.propTypes = {
  public1: PropTypes.string,
  public2: PropTypes.string,
  carousel: PropTypes.element,
  icon1: PropTypes.string,
  icon2: PropTypes.string,
  icon3: PropTypes.string,
  info1: PropTypes.string,
  info2: PropTypes.string,
  info3: PropTypes.string
};

P007.defaultProps = {
  public1: null,
  public2: null,
  carousel: null,
  icon1: null,
  icon2: null,
  icon3: null,
  info1: null,
  info2: null,
  info3: null
};
