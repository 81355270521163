import is from 'is_js';

export default class Profile {
  constructor(params) {
    const {
      address,
      avatar,
      birthday,
      city,
      email,
      firstName,
      gender,
      group,
      id,
      isBlock,
      isVerified,
      job,
      lastName,
      postalCode,
      region,
      telephone
    } = params;

    this.address = address ?? '';
    this.avatar = avatar;
    this.birthday = birthday ?? '';
    this.city = city ?? '';
    this.email = email ?? '';
    this.firstName = firstName ?? '';
    this.gender = gender ?? '';
    this.group = group ?? '';
    this.id = id;
    this.isBlock = isBlock;
    this.isVerified = isVerified;
    this.job = job ?? '';
    this.lastName = lastName ?? '';
    this.postalCode = postalCode ?? '';
    this.region = region ?? '';
    this.telephone = telephone ?? '';
  }

  get fullname() {
    // is english
    if (/[a-zA-Z0-9]/.test(this.lastName) || /[a-zA-Z0-9]/.test(this.firstName)) {
      return `${this.firstName} ${this.lastName}`;
    }
    return `${this.lastName}${this.firstName}`;
  }

  static fromData(data) {
    return new Profile(data);
  }

  static fromRes(data) {
    return new Profile(data);
  }
}
