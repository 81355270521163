import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import SignupPage from 'src/pages/SignupPage';
import LoginPage from 'src/pages/LoginPage';
import AdvertisementPage from 'src/pages/AdvertisementPage';
import LobbyPage from 'src/pages/LobbyPage';
import CenterPage from 'src/pages/CenterPage';
import PaymentPage from 'src/pages/PaymentPage';
import EcPayPage from 'src/pages/EcPayPage';
import NotFoundPage from 'src/pages/NotFoundPage';
import PayErrorPage from 'src/pages/PayErrorPage';
import PlanService from 'src/services/plans';
import GlobalModal from 'src/components/Modal';
import AppLabel from 'src/components/AppLabel';
import ConversationModule from 'src/components/ConversationModule';
import ChooseSalesPopupViewModel from 'src/viewModels/ChooseSalesPopupViewModel';
import MobileHorizontalAlert from './components/MobileHorizontalAlert';
import Loading from './components/Loading';
import ChooseSalesPopup from './components/ChooseSalesPopup';

import NavBar from './components/NavBar';
import CsBtn from './components/csBtn';
import NavBarViewModel from './viewModels/NavBarVIewModel';
import ConversationModuleViewModel from './viewModels/ConversationModuleViewModel';


import './App.css';

const LoadablePickOutPage = Loadable({
  loader: () => import('src/pages/PickOutPage'),
  loading: Loading,
  delay: 300,
  timeout: 30000
});

const LoadablePreOrderPage = Loadable({
  loader: () => import('src/pages/PreOrderPage'),
  loading: Loading,
  delay: 300,
  timeout: 30000
});

const LoadableOrderPage = Loadable({
  loader: () => import('src/pages/OrderPage'),
  loading: Loading,
  delay: 300,
  timeout: 30000
});

@observer
class App extends React.Component {
  constructor(props) {
    super(props);

    this.conversationViewModel = new ConversationModuleViewModel();
    this.navBarViewModel = new NavBarViewModel();
    this.chooseVm = new ChooseSalesPopupViewModel();
  }

  async componentDidMount() {
    try {
      // NOTE: redirect for old domain.
      const arr = window.location.hostname.split('.');
      const domain = arr[arr.length - 2];

      if (domain === 'subtle-net') {
        const { protocol, pathname, search } = window.location;
        const subdomain = arr.slice(0, arr.length - 2).join('.');

        window.location.replace(`${protocol}//${subdomain.length ? `${subdomain}.` : ''}maauu.com${pathname}${search}`);
      }
    } catch (err) {
      // ignore
    }

    // remove mask (if not puppeteer)
    if (navigator.userAgent !== 'puppeteer') {
      const mask = document.body.querySelector('#mask');
      if (mask) {
        document.body.removeChild(mask);
      }
    }

    const { actions } = this.props.appContext;

    try {
      const plan = await PlanService.getPlanInfo();
      actions.updatePlan(plan);
    } catch (err) {
      console.warn('Failed to update plan in context');
      actions.updatePlan({});
    }
  }

  render() {
    return (
      <>
        <AppLabel />

        <Router basename="/">
          <NavBar
            viewModel={this.navBarViewModel}
            unread={this.conversationViewModel.unread}
            openConversation={this.conversationViewModel.show}
            chatConversation={this.conversationViewModel.chat}
            isLoading={this.conversationViewModel.isLoading}
          />
          <Switch>
            <Route path="/pickout">
              <LoadablePickOutPage />
            </Route>
            <Route path="/order/:id">
              <LoadableOrderPage />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            {/* NOTE: change route name cuz advertisement will be block by adBlock */}
            <Route path="/display">
              <AdvertisementPage
                handleChangeSection={this.navBarViewModel.handleChangeSection}
              />
            </Route>
            <Route path="/center">
              <CenterPage />
            </Route>
            <Route path="/preorder/:id">
              <LoadablePreOrderPage />
            </Route>
            <Route path="/payment/:uid/:pid">
              <PaymentPage />
            </Route>
            <Route path="/ecpay/:uid/:pid">
              <EcPayPage />
            </Route>
            <Route path="/404">
              <NotFoundPage />
            </Route>
            <Route path='/payerror'>
              <PayErrorPage />
            </Route>
            <Route path={['/', '/home']}>
              <LobbyPage
                openConversation={this.conversationViewModel.show}
              />
            </Route>
          </Switch>
          <CsBtn
            unread={this.conversationViewModel.unread}
            openModalFunction={this.chooseVm.changeShow}
          />
          <ChooseSalesPopup
            viewModel={this.chooseVm}
            openConversation={this.conversationViewModel.chat}
            isLoading={this.conversationViewModel.isLoading}
          />
          <ConversationModule
            viewModel={this.conversationViewModel}
          />
        </Router>

        <GlobalModal />
        <MobileHorizontalAlert />

      </>
    );
  }
}

App.propTypes = {
  appContext: PropTypes.object
};

App.defaultProps = {
  appContext: null
};

export default withAppStateContext(App);
