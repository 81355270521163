import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Beforeunload } from 'react-beforeunload';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import withProfile from 'src/components/withProfile';
import PaymentViewModel from 'src/viewModels/Payment';
import { FlexView } from 'src/components/layout';
import OrderForm from 'src/components/OrderForm';
import Button from 'src/components/Common/Button';
import arrowImg from 'src/assets/goShoppingArrow.png';

import styles from './styles.module.css';
import Carousel from './components/Carousel';
import TemporaryCarousel from './components/TemporaryCarousel';

@observer
class Payment extends Component {
  constructor(props) {
    super(props);

    this.viewModel = new PaymentViewModel(props);
  }

  componentDidMount() {
    this.viewModel.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.viewModel.didUpdate(prevProps, this.props);
  }

  componentWillUnmount() {
    this.viewModel.willUnmount(this.props);
  }

  goEcPayPage = () => {
    this.viewModel.goEcPayPage(this.props);
  };

  _renderSidebar = () => {
    const ecPayUrl = this.viewModel.ecPayUrl(this.props);

    return (
      <div className={styles.sidebar_layout}>
        <div className={styles.title}>
          { this.viewModel.title }
        </div>
        <div className={styles.info_layout}>
          <div className={styles.door}>
            { `戶別: ${this.viewModel.household}` }
          </div>
          <div className={styles.gap} />
          <div className={styles.total_price}>
            {`價格: ${this.viewModel.priceLabel}`}
          </div>
        </div>
        {this.viewModel.paymentType === 'link' && ecPayUrl && (
        <>
          <div className={styles.qrcode_layout}>
            <QRCode value={ecPayUrl} size={120} />
          </div>
          <div className={styles.copy_link_layout}>
            <CopyToClipboard text={ecPayUrl}>
              <Button className={styles.copy_link}>複製過卡連結</Button>
            </CopyToClipboard>
          </div>
        </>
        )}
        <div className={styles.description}>
          {this.viewModel.orderDescription}
        </div>
        <div style={{ flex: 1 }}>
          <OrderForm disabled values={this.viewModel.ordererInfo} />
        </div>
        {
          this.viewModel.paymentType === 'direct' && (
            <div className={styles.direct_layout}>
              <Button className={styles.link_btn} onClick={this.goEcPayPage}>
                直接過卡
                <img className={styles.arrow_img} src={arrowImg} alt="arrow" />
              </Button>
            </div>
          )
        }
        <div className={styles.center_info}>只要登入會員中心，即可查看自己的下單記錄，也可以下載至載具存檔</div>
      </div>
    );
  }

  render() {
    return (
      <div className="page">
        <FlexView
          // left={<Carousel viewModel={this.viewModel.carouselViewModel} />}
          left={<TemporaryCarousel viewModel={this.viewModel.carouselViewModel} />}
          right={this._renderSidebar()}
        />
        <Beforeunload onBeforeunload={() => 'You\'ll lose your data!'} />
      </div>
    );
  }
}

Payment.defaultProps = {
  profile: null
};

Payment.propTypes = {
  profile: PropTypes.object
};

export default withProfile(withAppStateContext(withRouter(Payment)), false);
