import React from 'react';
import { makeObservable, observable, action, computed } from 'mobx';
import isMobile from 'ismobilejs';

import json from 'src/json/lobby.json';

import IconBtnViewModel from './IconBtn';

export default class LobbyViewModel {
  @observable backgroundImg = '';
  @observable icons = [];
  @observable windowHeight = 0;
  @observable windowWidth = 0;
  @observable scrollBarWidth = 0;

  constructor() {
    makeObservable(this);

    this.deserialize();
  }

  didMount = (props) => {
    if (!isMobile(window.navigator).phone) {
      window.addEventListener('resize', this.handleResize);
    }
    // workaround: prevent height & width issue.
    this.detectScrollBar();
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;

    // workaround: detect orientation and force resize.
    if (isMobile(window.navigator).phone && this.orientation === 'landscape') {
      this.windowHeight = window.innerWidth;
      this.windowWidth = window.innerHeight;
    }
  };

  willUnmount = (props) => {
    if (!isMobile(window.navigator).phone) {
      window.removeEventListener('resize', this.handleResize);
    }
  };

  @action
  detectScrollBar = () => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    outer.style.overflow = 'scroll';

    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;
    outer.parentNode.removeChild(outer);

    this.scrollBarWidth = (widthNoScroll - widthWithScroll);
  }

  @action
  deserialize = () => {
    const {
      backgroundImg,
      icons
    } = json;

    this.backgroundImg = backgroundImg;
    this.icons = icons.map((icon) => new IconBtnViewModel(icon));
  }

  @action
  handleResize = () => {
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
  }

  @computed
  get imgHeight() {
    const calculateHeight = this.windowWidth * 0.5625;
    const imgH = Math.max(this.windowHeight, calculateHeight);
    if (imgH === this.windowHeight) {
      return imgH - this.scrollBarWidth;
    }
    return (this.windowWidth - this.scrollBarWidth) * 0.5625;
  }

  @computed
  get imgWidth() {
    const calculateWidth = this.windowHeight / 0.5625;
    const imgW = Math.max(this.windowWidth, calculateWidth);
    if (imgW === this.windowWidth) {
      return imgW - this.scrollBarWidth;
    }
    return (this.windowHeight - this.scrollBarWidth) / 0.5625;
  }

  @computed
  get orientation() {
    return this.windowHeight > this.windowWidth ? 'portrait' : 'landscape';
  }
}
