import numeral from 'numeral';
import is from 'is_js';

export default class PreOrder {
  constructor(params) {
    const {
      type,
      id,
      ownerId,
      doorId,
      style,
      paymentType,
      transactionId,
      ordererName,
      ordererInfo,
      remark,
      updatedAt,
      door
    } = params;

    this.type = type;
    this.id = id;
    this.ownerId = ownerId;
    this.doorId = doorId;
    this.style = style;
    this.paymentType = paymentType;
    this.transactionId = transactionId;
    this.ordererName = ordererName;
    this.ordererInfo = ordererInfo;
    this.remark = remark;
    this.updatedAt = updatedAt;
    this.door = door;
  }

  get household() {
    return `${this.door.floor.building.name} ${this.door.floor.name} ${this.door.name}`;
  }

  get priceLabel() {
    return is.existy(this.door.price) ? `NT$ ${numeral(this.door.price).format()}` : '';
  }

  static fromRes(data) {
    return new PreOrder(data);
  }
}
