import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import { useForm } from 'react-hook-form';
import PhoneInput from 'src/components/Common/PhoneInput';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import { phoneRule } from 'src/constants';
import styles from './styles.module.css';
import common from '../../common.module.css';


export default function LoginForm({ onSubmit, loading, prePath }) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  return (
    <div className={common.content}>
      <fieldset className={common.form}>
        <h2 className={common.topic}>登入</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className={common.form_title}>
            手機號碼
            <span>※</span>
            <br />
            請輸入您的手機號碼，以接收驗證碼，完成登入或註冊手續。即可使用選戶功能與專員服務。
          </h3>
          <div className={styles.phone}>
            <PhoneInput
              control={control}
              name="phone"
              rules={{
                validate: (value) => phoneRule(value)
              }}
              placeholder="請輸入手機號碼"
              className={errors.phone && common.error_bottom}
              maxLength={10}
            />
            <ErrorMessage error={errors.phone} label="手機號碼輸入錯誤" />
          </div>
          <div className={common.submit}>
            <Button className={styles.loginButton} htmlType="submit" loading={loading}>驗證</Button>
          </div>
          <div className={styles.signup}>
            還沒有帳號？
            <Link
              to={{
                pathname: '/signup',
                state: { target: prePath }
              }}
              style={{ fontWeight: 600, color: '#666666', textDecoration: 'underline' }}
            >
              註冊
            </Link>
          </div>
        </form>
      </fieldset>
    </div>
  );
}

LoginForm.defaultProps = {
  onSubmit: () => {},
  loading: false
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};
