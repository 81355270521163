/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import is from 'is_js';
import isEmail from 'validator/lib/isEmail';

import useTwZipCode from 'src/hooks/useTwZipCode';
import useBirth from 'src/hooks/useBirth';
import useJob from 'src/hooks/useJob';
import Input from 'src/components/Common/Input';
import Radio from 'src/components/Common/Radio';
import PhoneInput from 'src/components/Common/PhoneInput';
import Select from 'src/components/Common/Select';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import Button from 'src/components/Common/Button';
import common from 'src/pages/common.module.css';
import arrowImg from 'src/assets/goShoppingArrow.png';
import blackArrowImg from 'src/assets/arrow_black.png';
import qrCodeImg from 'src/assets/preorder_qrcode.png';
import { phoneRule, convertTelephoneText } from 'src/constants';
import styles from './styles.module.css';


function PreOrderForm({ onSubmit, values, disabled }) {
  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({
    defaultValues: {
      gender: '男'
    }
  });

  const { yearOptions, monthOptions, dayOptions, onMonthChange } = useBirth();
  const { cityOptions, districtOptions, onCityChange } = useTwZipCode();
  const { jobOptions, onJobChange } = useJob();

  const paymentType = useRef();

  const genderRegistration = register('gender');
  const yearRegistration = register('year', { required: true });
  const monthRegistration = register('month', { required: true });
  const dayRegistration = register('day', { required: true });
  const cityRegistration = register('city', { required: true });
  const districtRegistration = register('region', { required: true });
  const jobRegistration = register('job', { required: true });

  const _convertBirth = (year, month, day) => {
    if (is.empty(year) || is.empty(month) || is.empty(day)) return null;
    const m = month < 10 ? `0${month}` : month;
    const d = day < 10 ? `0${day}` : day;
    return `${year}/${m}/${d}`;
  };

  const _onSubmit = (data) => {
    const req = {
      ...data,
      telephoneRequired: convertTelephoneText(data.telephone1),
      telephoneOptional: is.empty(data.telephone2) ? '' : convertTelephoneText(data.telephone2),
      birthday: _convertBirth(data.year, data.month, data.day),
      fullAddress: `${data.city}${data.region}${data.address}`,
      payment: paymentType.current
    };
    onSubmit(req);
  };

  const onDirectButtonClicked = () => {
    paymentType.current = 'direct';
    trigger();
  };

  const onLinkButtonClicked = (data) => {
    paymentType.current = 'link';
    trigger();
  };

  useEffect(() => {
    if (is.empty(values) || !values) return;
    const initAddress = async () => {
      setValue('city', values.city);
      // region 列表要先選擇 city 才會有
      await onCityChange(values.city);
      await setValue('region', values.region);
    };
    setValue('email', values.email);
    setValue('lastName', values.lastName);
    setValue('firstName', values.firstName);
    setValue('gender', values.gender);
    setValue('job', values.job);
    setValue('year', values.year);
    setValue('month', values.month);
    setValue('day', values.day);
    setValue('postalCode', values.postalCode);
    setValue('address', values.address);
    setValue('telephone1', values.telephone1);
    setValue('telephone2', values.telephone2);
    initAddress();
  }, [values]);

  return (
    <div>
      <fieldset disabled={disabled} className={common.content}>
        <form className={styles.layout} onSubmit={handleSubmit(_onSubmit)}>
          <h3 className={common.form_title}>
            電子郵件地址
            <span>※</span>
            <br />
            郵件敬請填寫正確資訊，以免喪失會員權益。
          </h3>
          <div className={styles.email}>
            <Input
              control={control}
              name="email"
              placeholder="請輸入電子郵件地址"
              rules={{
                require: true,
                validate: (value) => isEmail(value)
              }}
              className={errors.email && common.error_bottom}
              inputClassName={styles.form_input}
            />
            <ErrorMessage error={errors.email} label="格式不正確" />
          </div>
          <h3 className={common.form_title}>
            姓名
            <span>※</span>
          </h3>
          <div className={common.row}>
            <div className={styles.name_item}>
              <Input
                control={control}
                name="lastName"
                placeholder="姓"
                rules={{ required: true }}
                className={errors.lastName && common.error_bottom}
                inputClassName={styles.form_input}
              />
              <ErrorMessage error={errors.lastName} label="姓名不能為空" />
            </div>
            <div className={styles.name_item}>
              <Input
                control={control}
                name="firstName"
                placeholder="名"
                rules={{ required: true }}
                className={errors.firstName && common.error_bottom}
                inputClassName={styles.form_input}
              />
              <ErrorMessage error={errors.firstName} label="姓名不能為空" />
            </div>
          </div>
          <div className={common.row}>
            <div className={styles.name_item}>
              <h3 className={common.form_title}>
                稱謂
                <span>※</span>
              </h3>
              <Radio
                id="male"
                value="男"
                name="gender"
                label="男"
                style={{ marginRight: 20 }}
                {...genderRegistration}
              />
              <Radio
                id="female"
                value="女"
                name="gender"
                label="女"
                {...genderRegistration}
              />
            </div>
            <div className={styles.name_item}>
              <h3 className={common.form_title}>
                職業
                <span>※</span>
              </h3>
              <Select
                options={jobOptions}
                {...jobRegistration}
                onChange={(e) => {
                  onJobChange(e.target.value);
                  jobRegistration.onChange(e);
                }}
                className={clsx(styles.form_select, errors.job && common.error_border)}
              />
              <ErrorMessage error={errors.job} label="請選擇職業" />
            </div>
          </div>
          <h3 className={common.form_title}>
            出生日期
            <span>※</span>
          </h3>
          <div className={common.row}>
            <div className={styles.year}>
              <Select
                options={yearOptions}
                {...yearRegistration}
                className={clsx(styles.form_select, errors.year && common.error_border)}
              />
            </div>
            <div className={styles.month}>
              <Select
                name="month"
                options={monthOptions}
                {...monthRegistration}
                onChange={(e) => {
                  onMonthChange(e.target.value);
                  monthRegistration.onChange(e);
                }}
                className={clsx(styles.form_select, errors.month && common.error_border)}
              />
            </div>
            <div className={styles.day}>
              <Select
                options={dayOptions}
                {...dayRegistration}
                className={clsx(styles.form_select, errors.day && common.error_border)}
              />
            </div>
          </div>
          <ErrorMessage error={errors.year || errors.month || errors.day} label="請填寫出生日期" />
          <h3 className={common.form_title}>
            聯絡暨合約地址
            <span>※</span>
          </h3>
          <div className={clsx(common.row, styles.contract_address)}>
            <div className={styles.city}>
              <Select
                options={cityOptions}
                {...cityRegistration}
                onChange={(e) => {
                  onCityChange(e.target.value);
                  cityRegistration.onChange(e);
                }}
                className={clsx(styles.form_select, errors.city && common.error_border)}
              />
            </div>

            <div className={styles.district}>
              <Select
                options={districtOptions}
                {...districtRegistration}
                className={clsx(styles.form_select, errors.region && common.error_border)}
              />
            </div>
            <div className={styles.postal_code}>
              <Input
                control={control}
                name="postalCode"
                placeholder="郵遞區號"
                rules={{ required: true }}
                className={errors.postalCode && common.error_bottom}
                inputClassName={styles.form_input}
              />
            </div>
          </div>
          <div className={styles.address}>
            <Input
              control={control}
              name="address"
              placeholder="街道, 巷弄, 門號, 樓層"
              rules={{ required: true }}
              className={errors.address && common.error_bottom}
              inputClassName={styles.form_input}
            />
          </div>
          <ErrorMessage error={errors.city || errors.region || errors.postalCode || errors.address} label="地址填寫錯誤" />
          <div
            className={clsx(styles.phone_layout, !disabled && (errors.telephone1 ? styles.mb_10 : styles.mb_40))}
            style={{ backgroundColor: disabled && 'transparent' }}
          >
            <div>
              <PhoneInput
                control={control}
                name="telephone1"
                rules={{
                  required: true,
                  validate: (value) => phoneRule(value)
                }}
                placeholder="必填電話※"
                maxLength={10}
                inputStyle={{ backgroundColor: !disabled && '#f5f5f5' }}
                className={clsx(styles.telephone1, errors.telephone1 && common.error_bottom)}
              />
            </div>
            <div>
              <PhoneInput
                control={control}
                name="telephone2"
                rules={{
                  required: false,
                  validate: (value) => phoneRule(value) || value === ''
                }}
                placeholder="選填電話"
                maxLength={10}
                inputStyle={{ backgroundColor: !disabled && '#f5f5f5' }}
              />
            </div>
          </div>

          <ErrorMessage error={errors.telephone1} label="必填號碼錯誤" />
          {
          !disabled && (
          <div className={common.submit}>
            <Button
              className={styles.qrcode_btn}
              type="submit"
              onClick={onLinkButtonClicked}
            >
              付款連結
              <img src={qrCodeImg} alt="qrcode_icon" width={25} height={25} style={{ marginLeft: 5 }} />
              <img className={styles.arrow_img} src={blackArrowImg} alt="arrow" />
            </Button>
          </div>
          )
          }
          {
            !disabled && (
              <div className={common.submit}>
                <Button
                  className={styles.link_btn}
                  type="submit"
                  onClick={onDirectButtonClicked}
                >
                  直接付款
                  <img className={styles.arrow_img} src={arrowImg} alt="arrow" />
                </Button>
              </div>
            )
          }
        </form>
      </fieldset>
    </div>
  );
}

PreOrderForm.defaultProps = {
  onSubmit: () => {},
  values: {},
  disabled: false
};

PreOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  disabled: PropTypes.bool
};

export default PreOrderForm;
