import { makeObservable, observable, computed, action } from 'mobx';
import diff from 'deep-diff';
import is from 'is_js';
import dayjs from 'dayjs';

import ErrorMessageService from 'src/services/errorMessage';
import UserService from 'src/services/user';
import CarouselViewModel from 'src/viewModels/Carousel';
import buildingJSON from 'src/json/plan.json';
import otherJson from 'src/json/other.json';
import { Theme } from 'src/components/Modal/themes';
import { findDoorByNames, findBuildingByNames } from 'src/utils';

export default class PaymentViewModel {
  @observable buildings = [];
  @observable preorder = null;

  carouselViewModel = new CarouselViewModel();
  unblockNavigation = null;
  timeId = null;

  constructor(props) {
    makeObservable(this);
  }

  get orderDescription() {
    return this.preorder?.paymentType === 'direct'
      ? '您尚未完成下單，物件並不會保留，如欲繼續取得此物件優先磋商權，請點選下方「直接過卡」按鍵。'
      : otherJson.order.share;
  }

  @computed get paymentType() {
    return this.preorder?.paymentType;
  }

  @computed get title() {
    return this.preorder?.paymentType === 'direct' ? '優先磋商權確認' : '甜蜜分享';
  }


  @computed get ordererInfo() {
    try {
      return JSON.parse(this.preorder.ordererInfo);
    } catch (err) {
      return {};
    }
  }

  @computed get household() {
    return this.preorder?.household ?? '';
  }

  @computed get price() {
    return this.preorder?.door?.price ?? null;
  }

  @computed get priceLabel() {
    return this.preorder?.priceLabel ?? '';
  }

  didMount = async (props) => {
    const { appContext, history } = props;

    this.unblockNavigation = history.block((location) => {
      appContext.actions?.showModal({
        theme: Theme.error,
        image: otherJson.alert.payment.alert,
        title: otherJson.alert.payment.poptitle || 'OPPS',
        content: otherJson.alert.payment.popdiscription || '確定要離開這個頁面嗎？\n如果您想繼續此物件設計\n可以在會員中心找到您的清單。',
        okText: otherJson.alert.payment.alertbtn2 || '確認',
        cancelText: otherJson.alert.payment.alertbtn1 || '取消',
        onOk: () => {
          this.navigate(history, location.pathname);
        },
        onCancel: () => {}
      });

      return false;
    });
    // for mobile device scroll top
    window.scrollTo(0, 0);

    await this.setupPreorder(props);
    await this.setupSlides(); // depends on `setupPreorder`
    // check whether preOrder is deal, depending on setupPreOrder
    const intervalId = setInterval(async () => {
      try {
        if (this.preorder) {
          const trades = await UserService.getTrades(this.preorder.doorId);
          const dealTrade = trades.filter((trade) => trade.status === '1').sort((a, b) => b.updatedAt - a.updatedAt).shift();
          if (dealTrade) {
            this.unblockNavigation?.();
            history.push(`/order/${dealTrade.transactionId}?t=${dayjs().valueOf()}`);
            clearInterval(intervalId);
          }
          console.log('dealTrades: ', dealTrade);
        }
      } catch (err) {
        clearInterval(intervalId);
        console.log('err: ', err);
      }
    }, 1000 * 15);
    this.timeId = setTimeout(() => clearInterval(intervalId), 1000 * 60 * 5);
  };

  didUpdate = async (prevProps, props) => {
    if (diff(prevProps.profile, props.profile)) {
      await this.setupPreorder(props);
      await this.setupSlides();
    }
  };

  willUnmount = (props) => {
    this.unblockNavigation?.();
    clearTimeout(this.timeId);
  };

  navigate = (history, to, params) => {
    this.unblockNavigation?.();
    history.push(to, params);
  };

  @action setupSlides = async () => {
    try {
      if (!this.preorder) { return; }
      // 這邊的彈窗還不知道會改幾次
      // const meta = findDoorByNames(buildingJSON.buildings, this.preorder.door);
      // this.carouselViewModel.updateSlides(meta?.popup ?? []);
      const meta = findBuildingByNames(buildingJSON.buildings, this.preorder.door);
      this.carouselViewModel.updateSlides(meta?.pic ?? []);
    } catch (err) {
      console.log('setupSlides', err);
    }
  }

  @action setupPreorder = async (props) => {
    const { appContext, history, match } = props ?? {};

    try {
      if (!match.params.uid || !match.params.pid) { return; }

      this.preorder = await UserService.getPreOrder(match.params.uid, match.params.pid);

    } catch (err) {
      appContext.actions?.showModal({
        theme: Theme.error,
        title: 'ERROR',
        content: ErrorMessageService.getPreOrder(err),
        okText: '確認',
        onOk: () => {
          this.navigate(history, '/center');
        },
        onCancel: () => {
          this.navigate(history, '/center');
        }
      });
    }
  }

  ecPayUrl(props) {
    if (!this.preorder || !is.existy(this.price)) {
      return null;
    }
    const { match } = props ?? {};
    return `${window.location.origin}/ecpay/${match.params.uid}/${this.preorder.id}?price=${this.price}`;
  }

  goEcPayPage = (props) => {
    if (!this.preorder || !is.existy(this.price)) {
      return;
    }
    const { match } = props ?? {};
    this.navigate(props.history, `/ecpay/${match.params.uid}/${this.preorder.id}?price=${this.price}`);
  }
}
