/**
 * This source code is exported from pxCode, you can get more document from https://www.pxcode.io
 */
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';

export default function PC01(props) {
  return (
    <div className={`p-c01 ${css(styles.flex, styles.flex_layout)}`}>
      <div className={css(styles.flex1, styles.flex1_layout)}>
        <div className={css(styles.flex1_col)}>
          <h1 className={css(styles.hero_title, styles.hero_title_layout)}>
            {props.hero_title}
            <span className={css(styles.unit)}>
              {props.descriptionUnit}
            </span>
          </h1>
        </div>
      </div>

      <div className={css(styles.flex_row)}>
        <div className={css(styles.text_body, styles.text_body_layout)}>
          {props.text_body}
        </div>
      </div>
    </div>
  );
}

PC01.inStorybook = true;

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    flexDirection: 'column'
  },
  flex_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: 0
  },
  flex1: {
    display: 'flex',
    justifyContent: 'center'
  },
  flex1_layout: {
    position: 'relative',
    overflow: 'visible',
    margin: 0
  },
  flex1_col: {
    display: 'flex',
    flex: '0 1 56px'
  },
  hero_title: {
    display: 'flex',
    justifyContent: 'center',
    font: '400 40px "Roboto", "Noto Sans TC", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
    '@media (max-width: 575.98px)': {
      justifyContent: 'center',
      fontSize: '35px',
      textAlign: 'center'
    }
  },
  hero_title_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: 0,
    display: 'flex',
    alignItems: 'baseline'
  },
  unit: {
    fontSize: 28
  },
  flex1_col1: {
    display: 'flex',
    flex: '0 1 32px'
  },
  big_title: {
    display: 'flex',
    justifyContent: 'center',
    font: '28px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '2.25px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '23px',
      textAlign: 'center'
    }
  },
  big_title_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: '7px 0px 3px'
  },
  flex_row: {
    display: 'flex'
  },
  text_body: {
    display: 'flex',
    justifyContent: 'center',
    font: '13px/1.6923076923076923 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    '@media (max-width: 575.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '10px',
      textAlign: 'center'
    }
  },
  text_body_layout: {
    position: 'relative',
    height: 22,
    width: 'fit-content',
    margin: '0px auto'
  }
});

PC01.propTypes = {
  hero_title: PropTypes.string,
  text_body: PropTypes.string,
  descriptionUnit: PropTypes.string
};

PC01.defaultProps = {
  hero_title: null,
  text_body: null,
  descriptionUnit: null
};
