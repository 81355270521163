import React, { useState } from 'react';
import Modal from 'react-modal';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.css';

Modal.setAppElement('#root');

function AvatarModal({ selectedAvatar, avatars, visible, onOk, onCancel }) {
  const [loading, setLoading] = useState(false);

  const _onOk = async (idx) => {
    setLoading(true);
    await onOk(idx);
    setLoading(false);
  };

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onCancel}
      className={styles.react_modal_content}
      overlayClassName={styles.react_modal_overlay}
    >
      <div className={styles.title}>
        請選擇顯示圖片
        {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
      </div>
      <div className={styles.content}>
        {
          avatars?.map((avatar, index) => <div style={{ borderColor: selectedAvatar === index && '#3E6BE1' }} className={styles.avatar} onClick={() => _onOk(index)} key={index}><img width={90} height={90} style={{ borderRadius: '50%' }} src={avatar} alt="avatar" /></div>)
        }
      </div>
      <div className={styles.close} />
    </Modal>
  );
}

AvatarModal.defaultProps = {
  avatars: [],
  visible: false,
  selectedAvatar: 0,
  onOk: () => {},
  onCancel: () => {}
};

AvatarModal.propTypes = {
  avatars: PropTypes.array,
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  selectedAvatar: PropTypes.number
};

export default AvatarModal;
