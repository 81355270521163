/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';
import C01 from './C01';
import arrowImg from '../../../../../assets/goShoppingArrow.png';

// TODO: i changed some of the file direction.

function renderMethod153(props) {
  return (
    <Px.div
      data-background={props.background}
      x="0px 1920fr 0px"
      y="0 minmax(0px, 960fr) 0"
      className={`${css(method153Styles.group)} swiper-lazy`}
    >
      <Px.div
        x="512fr 625fr 783fr"
        y="285fr minmax(0px, max-content) 168fr"
        className={css(method153Styles.flex)}
        xxlX="512fr 625fr 783fr"
        xxlY="143fr minmax(0px, max-content) 84fr"
        xlX="512fr 625fr 783fr"
        xlY="72fr minmax(0px, max-content) 42fr"
        lgX="512fr 625fr 783fr"
        lgY="36fr minmax(0px, max-content) 25fr"
        mdX="512fr 625fr 783fr"
        mdY="25fr minmax(0px, max-content) 25fr"
      >
        <Px.img
          src={props.casetitle}
          x="136fr 489fr 0px"
          y="0px minmax(0px, 363fr) 0px"
          className={css(method153Styles.casetitle)}
        />

        <Px.div
          x="1fr minmax(0px, max-content) 365fr"
          y="18px minmax(0px, 35fr) 0px"
          className={css(method153Styles.subtitle1)}
          xsX="1fr minmax(0px, max-content) 365fr"
          xsY="25px minmax(0px, 35fr) 0px"
        >
          <div
            className={css(
              method153Styles.subtitle11,
              method153Styles.subtitle11_layout
            )}
          >
            {props.subtitle1}
          </div>
        </Px.div>

        <Px.div
          x="32fr minmax(0px, max-content) 333fr"
          y="1px minmax(0px, 35fr) 0px"
          className={css(method153Styles.subtitle2)}
          xsX="32fr minmax(0px, max-content) 333fr"
          xsY="25px minmax(0px, 35fr) 0px"
        >
          <div
            className={css(
              method153Styles.subtitle21,
              method153Styles.subtitle21_layout
            )}
          >
            {props.subtitle2}
          </div>
        </Px.div>

        <div
          className={css(
            method153Styles.decorator
          )}
          onClick={props.openVideo}
        >
          <img
            src={props.videoIcon}
            alt="playBtn"
            className={css(method153Styles.videoIcon)}
          />
          <div className={css(method153Styles.videoName)}>
            {props.videoName}
          </div>
        </div>
      </Px.div>
    </Px.div>
  );
}

export default function Promo_001(props) {
  return (
    <div className={`promo-001 ${css(styles.group, styles.group_layout)}`}>
      <div className={css(styles.content_box, styles.content_box_layout)}>
        <div className={css(styles.content_box_row)}>
          {renderMethod153(props)}
        </div>
        <div className={css(styles.content_box_row1)}>
          <Px.div
            x="405fr minmax(max-content, 1110fr) 405fr"
            y="26fr minmax(0px, 72fr) 22fr"
            className={css(styles.flex)}
            xxlX="200fr minmax(max-content, 1110fr) 200fr"
            xxlY="26fr minmax(0px, 92fr) 22fr"
            xlX="102fr minmax(max-content, 1110fr) 102fr"
            xlY="26fr minmax(0px, 92fr) 22fr"
            lgX="51fr minmax(max-content, 1110fr) 51fr"
            lgY="26fr minmax(0px, 92fr) 22fr"
            mdX="26fr minmax(max-content, 1110fr) 26fr"
            mdY="26fr minmax(0px, 92fr) 22fr"
            smX="13fr minmax(max-content, 1110fr) 13fr"
            smY="26fr minmax(0px, 72fr) 22fr"
            xsX="10fr minmax(max-content, 1110fr) 10fr"
            xsY="26fr minmax(0px, 72fr) 22fr"
          >
            <div className={css(styles.flex_col)}>
              <Px.div
                x="1fr 710fr 0px"
                y="0px minmax(0px, 72fr) 0px"
                className={css(styles.flex)}
              >
                <div className={css(styles.flex1_col)}>
                  <Px.div
                    x="0px 100.5fr 0px"
                    y="1fr minmax(0px, max-content) 1fr"
                    className={css(styles.component)}
                  >
                    <C01
                      hero_title={props.description1}
                      descriptionUnit={props.description1Unit}
                      text_body={props.intro1}
                    />
                  </Px.div>
                </div>
                <div className={css(styles.flex1_spacer)} />
                <div className={css(styles.flex1_col1)}>
                  <Px.div
                    x="0px 112fr 0px"
                    y="1fr minmax(0px, max-content) 1fr"
                    className={css(styles.component)}
                  >
                    <C01
                      hero_title={props.description2}
                      descriptionUnit={props.description2Unit}
                      text_body={props.intro2}
                    />
                  </Px.div>
                </div>
                <div className={css(styles.flex1_spacer)} />
                <div className={css(styles.flex1_col2)}>
                  <Px.div
                    x="0px 113fr 0px"
                    y="1fr minmax(0px, max-content) 1fr"
                    className={css(styles.component)}
                  >
                    <C01
                      hero_title={props.description3}
                      descriptionUnit={props.description3Unit}
                      text_body={props.intro3}
                    />
                  </Px.div>
                </div>
                <div className={css(styles.flex1_spacer)} />
                <div className={css(styles.flex1_col3)}>
                  <Px.div
                    x="0px 170fr 0px"
                    y="1fr minmax(0px, max-content) 1fr"
                    className={css(styles.component)}
                  >
                    <C01
                      hero_title={props.description4}
                      descriptionUnit={props.description4Unit}
                      text_body={props.intro4}
                    />
                  </Px.div>
                </div>
              </Px.div>
            </div>
            <div className={css(styles.flex_spacer)} />
            <Link
              to="/pickout"
              className={css(styles.flex_col1)}
            >
              <Px.div
                x="0px 300fr 0px"
                y="10fr minmax(0px, max-content) 10fr"
                className={css(styles.shoppingbutton)}
                style={{
                  borderRadius: props.shoppingbutton.curveFillet,
                  backgroundColor: props.shoppingbutton.color
                }}
              >
                <div className={css(styles.flex2, styles.flex2_layout)}>
                  <div className={css(styles.flex2_col)}>
                    <div
                      className={css(styles.shoppingbutton1)}
                    >
                      {props.shoppingbutton.text}
                    </div>
                  </div>
                  <div className={css(styles.flex2_col1)}>
                    <Px.img
                      src={arrowImg}
                      x="1fr 51.5fr 1fr"
                      y="8.32fr minmax(0px, max-content) 8.32fr"
                      className={css(styles.img)}
                    />
                  </div>
                </div>
              </Px.div>
            </Link>
          </Px.div>
        </div>
      </div>
    </div>
  );
}

Promo_001.inStorybook = true;

const method153Styles = StyleSheet.create({
  group: {
    display: 'flex',
    background: ' center center / cover no-repeat',
    width: '100%',
    height: '100%'
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  casetitle: {
    width: '100%',
    minWidth: '400px',
    height: 'auto'
  },
  subtitle1: {
    display: 'flex',
    backgroundColor: 'rgb(248,248,246)',
    width: '100%',
    height: '100%'
  },
  subtitle11: {
    display: 'flex',
    justifyContent: 'center',
    font: '300 14px/2.142857142857143 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '12px',
      textAlign: 'center'
    }
  },
  subtitle11_layout: {
    position: 'relative',
    width: 'fit-content',
    margin: '2px 18px 3px 25px'
  },
  subtitle2: {
    display: 'flex',
    backgroundColor: 'rgb(248,248,246)',
    width: '100%',
    height: '100%'
  },
  subtitle21: {
    display: 'flex',
    justifyContent: 'center',
    font: '300 14px/2.142857142857143 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'center',
    letterSpacing: '1px',
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'center',
      fontSize: '12px',
      textAlign: 'center'
    }
  },
  subtitle21_layout: {
    position: 'relative',
    flexGrow: 1,
    margin: '3px 73px 2px 51px',
    '@media (max-width: 991.98px)': {
      flexGrow: 1,
      margin: '3px 51px 2px'
    },
    '@media (max-width: 767.98px)': {
      flexGrow: 1,
      margin: '3px 51px 2px'
    }
  },
  decorator: {
    display: 'flex',
    alignItems: 'center',
    margin: ' 25px 0 0 108px',
    cursor: 'pointer',
    ':hover': {
      opacity: 0.7
    }
  },
  videoIcon: {
    width: '30px !important',
    height: '30px !important',
    objectFit: 'contain !important',
    marginRight: '10px'
  },
  videoName: {
    whiteSpace: 'nowrap',
    color: '#fff',
    lineHeight: '22.4px',
    letterSpacing: '0.73px',
    font: ' 600 16px "Noto Sans TC", "Roboto", serif'
  }
});

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'visible',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  content_box: {
    display: 'flex',
    flexDirection: 'column'
  },
  content_box_layout: {
    position: 'relative',
    overflow: 'hidden',
    flexGrow: 1,
    margin: 0
  },
  content_box_row: {
    display: 'flex',
    flex: '1 1 960px'
  },
  content_box_row1: {
    display: 'flex',
    flex: '1 1 120px'
  },
  flex: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  flex_col: {
    display: 'flex',
    flex: '0 1 710px'
  },
  flex1_col: {
    display: 'flex',
    flex: '0 1 100.5px',
    marginRight: '10px'
  },
  component: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  flex1_spacer: {
    display: 'flex',
    flex: '0 1 70px'
  },
  flex1_col1: {
    display: 'flex',
    flex: '0 1 112px',
    marginRight: '10px'
  },
  flex1_col2: {
    display: 'flex',
    flex: '0 1 113px',
    marginRight: '10px'
  },
  flex1_col3: {
    display: 'flex',
    flex: '0 1 170px'
  },
  flex_spacer: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 30
  },
  flex_col1: {
    display: 'flex',
    flex: '0 1 300px'
  },
  shoppingbutton: {
    display: 'flex',
    backgroundColor: 'rgb(41,41,41)',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.7'
    }
  },
  flex2: {
    display: 'flex',
    justifyContent: 'center'
  },
  flex2_layout: {
    position: 'relative',
    overflow: 'visible',
    flexGrow: 1,
    margin: '12px 26.5px',
    '@media (max-width: 1199.98px)': {
      flexGrow: 1,
      margin: 12
    }
  },
  flex2_col: {
    display: 'flex',
    flex: '0 1 185px'
  },
  shoppingbutton1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    font: '18px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(255,255,255)',
    textAlign: 'center',
    letterSpacing: '5.625px',
    width: '100%',
    height: '100%',
    '@media (max-width: 1199.98px)': {
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
      textAlign: 'center'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px',
      textAlign: 'center'
    }
  },
  flex2_spacer: {
    display: 'flex',
    flex: '0 1 1.5px'
  },
  flex2_col1: {
    display: 'flex',
    flex: '0 1 43.5px'
  },
  img: {
    width: '100%',
    height: 'auto'
  }
});

Promo_001.propTypes = {
  description1: PropTypes.string,
  description2: PropTypes.string,
  description3: PropTypes.string,
  description4: PropTypes.string,
  description1Unit: PropTypes.string,
  description2Unit: PropTypes.string,
  description3Unit: PropTypes.string,
  description4Unit: PropTypes.string,
  intro1: PropTypes.string,
  intro2: PropTypes.string,
  intro3: PropTypes.string,
  intro4: PropTypes.string,
  shoppingbutton: PropTypes.object
};

Promo_001.defaultProps = {
  description1: null,
  description2: null,
  description3: null,
  description4: null,
  description1Unit: null,
  description2Unit: null,
  description3Unit: null,
  description4Unit: null,
  intro1: null,
  intro2: null,
  intro3: null,
  intro4: null,
  shoppingbutton: null
};
