export default class MonthlyPayment {
  constructor(params) {
    const { monthlyInterestRate } = params ?? {};

    this.monthlyInterestRate = monthlyInterestRate;
  }

  static fromRes(data) {
    return new MonthlyPayment(data);
  }
}
