import React from 'react';

import { FieldType } from 'src/constants';
import YouTube from 'src/components/YouTube';
import Image from 'src/components/Image';
import Iframe from 'src/components/Iframe';
import Video from 'src/components/Video';

function createSlideView(model, ref, className) {
  const { type, value, ...others } = model;
  switch (type) {
    case FieldType.Image:
      return <Image ref={ref} value={value} isLazy />;
    case FieldType.Iframe:
      return <Iframe ref={ref} value={value} />;
    case FieldType.Video:
      return <Video ref={ref} value={value} />;
    case FieldType.YouTube:
      return <YouTube ref={ref} value={value} {...others} className={className} />;
    default:
      throw new Error(`Can not find ${type}`);
  }
}

export { createSlideView };
