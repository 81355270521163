import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { avatars } from 'src/constants';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import CustomizeModal from 'src/components/CustomizeModal';
import withProfile from '../withProfile';

import styles from './styles.module.css';
import chatIconWhite from '../../assets/chatIconWhite.png';
import commCloseBtn from '../../assets/commCloseBtn.png';

@observer
class ChooseSalesPopup extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    const { profile, viewModel } = this.props;
    viewModel.didUpdate(profile);
  }

  render() {
    const { viewModel, openConversation } = this.props;
    return (
      <>
        {/* WEB */}
        <MediaQuery minWidth={768}>
          <div className={clsx(styles.mainContainer, !viewModel.isShowBox && styles.noShow)}>
            <div className={styles.upperContent}>
              <div
                className={styles.closeBtn}
                onClick={viewModel.changeShow}
              >
                <img
                  src={commCloseBtn}
                  alt="-"
                />
              </div>
              <div className={styles.upperContentText}>
                How can we help?
              </div>
            </div>
            <div className={clsx(styles.salesChooseContainer)}>
              <div className={styles.titleContainer}>
                <div className={styles.circle}>
                  <img src={chatIconWhite} alt="icon" className={styles.startIcon} />
                </div>
                <div className={styles.textContainer}>
                  <div className={styles.title}>開始對話</div>
                  <div className={styles.titleDescription}>我們提供專業的視訊服務，讓專家為您排除疑難雜症。</div>
                </div>
              </div>
              <div className={styles.salesOuterContainer}>
                {
                  !this.props.isLoading
                    ? (
                      <>
                        {
                          viewModel.salesList.map((sales) => (
                            <div
                              key={sales.id}
                              className={styles.salesContainer}
                              onClick={() => viewModel.handleOpenConversation(openConversation, sales.chatId)}
                            >
                              <div className={styles.avatarContainer}>
                                <img src={avatars[sales.avatar]} alt="avatar" className={styles.avatar} />
                                {
                                  sales.status === 'online'
                                    ? (
                                      <div className={styles.status} />
                                    )
                                    : null
                                }
                              </div>
                              <div className={styles.nameContainer}>
                                <div className={styles.name}>{sales.name}</div>
                              </div>
                            </div>
                          ))
                        }
                      </>
                    )
                    : (
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 60, position: 'absolute', top: '50%', right: 'calc(50% - 30px)' }} spin />} />
                    )
                }
              </div>
            </div>
          </div>
        </MediaQuery>
        {/* MOBILE */}
        <MediaQuery maxWidth={767}>
          <CustomizeModal
            className={clsx(styles.modal, !viewModel.isShow && styles.outerNoShow)}
          >
            <div className={clsx(styles.mainContainer, viewModel.isShowBox && styles.innerShow)}>
              <div className={styles.upperContent}>
                <div
                  className={styles.closeBtn}
                  onClick={viewModel.combineHideModal}
                >
                  <img
                    src={commCloseBtn}
                    alt="-"
                  />
                </div>
                <div className={styles.upperContentText}>
                  How can we help?
                </div>
              </div>
              <div className={clsx(styles.salesChooseContainer)}>
                <div className={styles.titleContainer}>
                  <div className={styles.circle}>
                    <img src={chatIconWhite} alt="icon" className={styles.startIcon} />
                  </div>
                  <div className={styles.textContainer}>
                    <div className={styles.title}>開始對話</div>
                    <div className={styles.titleDescription}>我們提供專業的視訊服務，讓專家為您排除疑難雜症。</div>
                  </div>
                </div>
                <div className={styles.salesOuterContainer}>
                  {
                    !this.props.isLoading
                      ? (
                        <>
                          {
                            viewModel.salesList.map((sales) => (
                              <div
                                key={sales.id}
                                className={styles.salesContainer}
                                onClick={() => viewModel.handleOpenConversation(openConversation, sales.chatId)}
                              >
                                <div className={styles.avatarContainer}>
                                  <img src={avatars[sales.avatar]} alt="avatar" className={styles.avatar} />
                                  {
                                    sales.status === 'online'
                                      ? (
                                        <div className={styles.status} />
                                      )
                                      : null
                                  }
                                </div>
                                <div className={styles.nameContainer}>
                                  <div className={styles.name}>{sales.name}</div>
                                </div>
                              </div>
                            ))
                          }
                        </>
                      )
                      : (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 60, position: 'absolute', top: '50%', left: 'calc(50% - 30px)' }} spin />} />
                      )
                  }
                </div>
              </div>
            </div>
          </CustomizeModal>
        </MediaQuery>
      </>
    );
  }
}

ChooseSalesPopup.propTypes = {
  viewModel: PropTypes.object,
  openConversation: PropTypes.func,
  isLoading: PropTypes.bool,
  profile: PropTypes.object
};

ChooseSalesPopup.defaultProps = {
  viewModel: null,
  openConversation: null,
  isLoading: true,
  profile: {}
};

export default withProfile(ChooseSalesPopup, false);
