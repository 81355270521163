import React, { useState, useRef, useEffect } from 'react';
import useCountDown from 'react-countdown-hook';
import { useHistory, Link } from 'react-router-dom';

import ErrorMessageService from 'src/services/errorMessage';
import Popup from 'src/components/Popup';
import PopupsViewModel from 'src/viewModels/Popup/PopupsViewModel';
import { consumerRule, privacys, signupRule } from 'src/constants';
import Footer from 'src/components/Footer';
import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import UserService from 'src/services/user';
import { Theme } from 'src/components/Modal/themes';
import OtpForm from 'src/components/OtpForm';
import SignupForm from './components/SignupForm';
import styles from './styles.module.css';

const initialTime = 300 * 1000;
const interval = 1000;
export default function SignupPage() {
  const { actions } = useAppStateContext();
  const history = useHistory();
  const prePath = history.location?.state?.target ?? '/center';
  const [step, setStep] = useState('step1');
  const [timeLeft, { start }] = useCountDown(initialTime, interval);
  const [id, setId] = useState('');
  const popupVm = useRef(new PopupsViewModel(privacys));
  const [loading, setLoading] = useState(false);
  const [isSignupAgree, setAgree] = useState(false);

  const handleError = async (cb, errorService) => {
    try {
      await cb();
    } catch (err) {
      actions.showModal({
        theme: Theme.error,
        title: 'ERROR',
        content: errorService(err),
        okText: '確認',
        onOk: () => { }
      });
    }
  };

  const onSignupClick = async (data) => {
    setLoading(true);
    await handleError(async () => {
      await UserService.signup(data);
      await UserService.otp(data.telephone);
      setId(data.telephone);
      setStep('step2');
      start();
    }, ErrorMessageService.signup);

    setLoading(false);
  };

  const onOtpClick = async (data) => {
    setLoading(true);
    const { otp } = data;
    await handleError(async () => {
      const { profile } = await UserService.login(id, otp);
      actions.updateProfile(profile);
      history.replace(prePath);
    }, ErrorMessageService.login);
    setLoading(false);
  };

  const onTimeUp = async () => {
    await handleError(async () => {
      await UserService.otp(id);
      start();
    }, ErrorMessageService.otp);
  };

  const onSignupRuleClick = () => {
    const list = signupRule(isSignupAgree,() => {
      setAgree(true);
      popupVm.current.closePopup();
    });
    popupVm.current.createLists(list);
    popupVm.current.showPopup();
  }

  useEffect(() => {
    onSignupRuleClick();
  }, []);

  return (
    <div className="page">
      {
        step === 'step1' ? (
          <SignupForm
            onSubmit={onSignupClick}
            onPrivacyClick={() => {
              popupVm.current.createLists(privacys);
              popupVm.current.showPopup();
            }}
            onCookieClick={() => {
              popupVm.current.createLists(consumerRule);
              popupVm.current.showPopup();
            }}
            onSignupRuleClick={onSignupRuleClick}
            isSignupAgree={isSignupAgree}
            loading={loading}
            prePath={prePath}
          />
        ) : (
          <div className={styles.content}>
            <div className={styles.form_layout}>
              <h2 className={styles.topic}>驗證</h2>
              <OtpForm
                onSubmit={onOtpClick}
                seconds={timeLeft / 1000}
                onTimeUp={onTimeUp}
                loading={loading}
              />
              <div className={styles.login}>
                已經有帳號？
                <Link to="/login" style={{ fontWeight: 600, color: '#666666', textDecoration: 'underline' }}>登入</Link>
              </div>
            </div>
          </div>
        )
      }
      <Footer>
        <span>2021 subtle© All Rights Reserved. v1.01</span>
        <span
          className={styles.privacy}
          onClick={() => {
            popupVm.current.createLists(privacys);
            popupVm.current.showPopup();
          }}
        >
          隱私權政策
        </span>
      </Footer>
      <Popup popupListVm={popupVm.current} />
    </div>
  );
}
