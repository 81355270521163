/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { createSlideView } from './Factory';
import styles from './styles.module.css';
import './customSwiper.css';

SwiperCore.use([Navigation, Pagination]);

@observer
class Carousel extends Component {
  constructor(props) {
    super(props);
  }

  _renderBullet = (index, className) => {
    const { viewModel } = this.props;
    const bulletStyle = viewModel.activeIndex === index ? 'background-color:#282828;width:20px;height:20px;' : 'width:10px;height:10px;margin:5px;';
    return `<span class="${className}" style="
    ${bulletStyle}
    ">
    </span>`;
  }

  componentDidMount() {
    this.props.viewModel.onEnter();
  }

  render() {
    const { viewModel, children } = this.props;
    return (
      <Swiper
        navigation={{
          prevEl: '.slidePre',
          nextEl: '.slideNext'
        }}
        pagination={{
          clickable: true,
          renderBullet: this._renderBullet,
          modifierClass: 'custom-pagination-'
        }}
        onActiveIndexChange={viewModel.onSlideChange}
        onSwiper={viewModel.onSwiper}
        className="swiper"
      >
        {viewModel.slides.map((el, index) => (
          <SwiperSlide key={index}>
            {createSlideView(el)}
          </SwiperSlide>
        ))}
        <div className={clsx(styles.slide_pre, 'slidePre')} />
        <div className={clsx(styles.slide_next, 'slideNext')} />
        { children }
      </Swiper>
    );
  }
}

Carousel.defaultProps = {
  viewModel: {},
  children: null
};

Carousel.propTypes = {
  viewModel: PropTypes.object,
  children: PropTypes.node
};

export default Carousel;
