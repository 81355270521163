import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.css';

export const NAVBAR_HEIGHT = 50;
export const MEDIA_MOBILE = 768;

/*
┌─────────┬────┐
│    xx   │ b  │
│         │ a  │
│    xx   │ r  │
│         │    │
│    xx   │    │
└─────────┴────┘
*/

function FlexView({ left, right, rightContentRef, isOrder }) {

  return (
    <div className={styles.layout}>
      <div className={styles.left}>{left}</div>
      <div
        className={clsx(styles.right, isOrder && styles.is_order)}
        ref={rightContentRef}
      >
        {right}
      </div>
    </div>
  );
}

FlexView.defaultProps = {
  left: null,
  right: null,
  isOrder: false
};

FlexView.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
  isOrder: PropTypes.bool
};

export { FlexView };
