import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import is from 'is_js';
import isEmail from 'validator/lib/isEmail';

import useTwZipCode from 'src/hooks/useTwZipCode';
import useBirth from 'src/hooks/useBirth';
import useJob from 'src/hooks/useJob';
import Input from 'src/components/Common/Input';
import Radio from 'src/components/Common/Radio';
import Select from 'src/components/Common/Select';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import styles from './styles.module.css';

function Form({ onSubmit, values, disabled }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm();
  const { yearOptions, monthOptions, dayOptions, onMonthChange } = useBirth();
  const { cityOptions, districtOptions, onCityChange } = useTwZipCode();
  const { jobOptions, onJobChange } = useJob();

  const genderRegistration = register('gender');
  const yearRegistration = register('year');
  const monthRegistration = register('month');
  const dayRegistration = register('day');
  const cityRegistration = register('city');
  const districtRegistration = register('region');
  const jobRegistration = register('job');

  const _convertBirth = (year, month, day) => {
    if (is.empty(year) || is.empty(month) || is.empty(day)) return null;
    const m = month < 10 ? `0${month}` : month;
    const d = day < 10 ? `0${day}` : day;
    const birth = `${year}${m}${d}`;
    return Number(birth);
  };

  const _onSubmit = async (data) => {
    const req = {
      ...data,
      gender: Number(data.gender),
      birthday: _convertBirth(data.year, data.month, data.day)
    };

    await onSubmit(req);
  };

  useEffect(() => {
    if (is.empty(values) || !values) return;
    setValue('email', values.email);
    setValue('lastName', values.lastName);
    setValue('firstName', values.firstName);
    setValue('gender', (values.gender).toString());
    setValue('job', values.job);
    setValue('postalCode', values.postalCode);
    setValue('address', values.address);
  }, [values]);

  useEffect(() => {
    if (is.empty(values) || !values) return;
    const initAddress = async () => {
      setValue('city', values.city);
      // region 列表要先選擇 city 才會有
      await onCityChange(values.city);
      await setValue('region', values.region);
    };

    initAddress();
  }, [values]);

  useEffect(() => {
    if (is.empty(values) || !values) return;
    const initBirth = () => {
      if (values.birthday !== '') {
        const year = values.birthday.toString().slice(0, 4);
        const month = values.birthday.toString().slice(4, 6);
        const day = values.birthday.toString().slice(6, 8);
        setValue('year', year);
        // 處理零開頭的個位數， ex: 06 轉成 6
        setValue('month', (month ? Number(month).toString() : ''));
        setValue('day', (day ? Number(day).toString() : ''));
      }
    };

    initBirth();
  }, [values]);

  return (
    <div className={styles.layout}>
      <fieldset disabled={disabled}>
        <form id="userCardForm" onSubmit={handleSubmit(_onSubmit)}>
          <div className={styles.row}>
            <div className={styles.form_title}>帳號</div>
            <div className={styles.phone}>{values.telephone}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.form_title}>
              電子郵件地址
              <span>※</span>
            </div>
            <div className={styles.email}>
              <Input
                control={control}
                name="email"
                placeholder="請輸入電子郵件地址"
                rules={{
                  validate: (value) => isEmail(value)
                }}
                inputClassName={styles.form_input}
              />
            </div>
          </div>
          <ErrorMessage error={errors.email} label="格式不正確" />
          <div className={styles.row}>
            <div className={styles.form_title}>
              姓名
              <span>※</span>
            </div>
            <div className={styles.lastName}>
              <Input
                control={control}
                name="lastName"
                placeholder="姓"
                rules={{ required: true }}
                inputClassName={styles.form_input}
              />
            </div>
            <div className={styles.firstName}>
              <Input
                control={control}
                name="firstName"
                placeholder="名"
                rules={{ required: true }}
                inputClassName={styles.form_input}
              />
            </div>
          </div>
          <ErrorMessage error={errors.firstName || errors.lastName} label="姓名不能為空" />
          <div className={styles.row}>
            <div className={styles.form_title}>
              稱謂與職業
            </div>
            <div className={styles.male}>
              <Radio
                id="male"
                value="1"
                name="gender"
                label="男"
                {...genderRegistration}
              />
            </div>
            <div className={styles.female}>
              <Radio
                id="female"
                value="0"
                name="gender"
                label="女"
                {...genderRegistration}
              />
            </div>
            <div className={styles.job}>
              <Select
                className={styles.form_select}
                options={jobOptions}
                {...jobRegistration}
                onChange={(e) => {
                  onJobChange(e.target.value);
                  jobRegistration.onChange(e);
                }}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.form_title}>生日</div>
            <div className={styles.year}>
              <Select
                className={styles.form_select}
                options={yearOptions}
                {...yearRegistration}
              />
            </div>
            <div className={styles.month}>
              <Select
                className={styles.form_select}
                options={monthOptions}
                {...monthRegistration}
                onChange={(e) => {
                  onMonthChange(e.target.value);
                  monthRegistration.onChange(e);
                }}
              />
            </div>
            <div className={styles.day}>
              <Select
                className={styles.form_select}
                options={dayOptions}
                {...dayRegistration}
              />
            </div>
          </div>
          {
            values.group === 'consumer' && (
            <>
              <div className={styles.row}>
                <div className={styles.form_title}>地址</div>
                <div className={styles.city}>
                  <Select
                    className={styles.form_select}
                    options={cityOptions}
                    {...cityRegistration}
                    onChange={(e) => {
                      onCityChange(e.target.value);
                      cityRegistration.onChange(e);
                    }}
                  />
                </div>
                <div className={styles.region}>
                  <Select
                    className={styles.form_select}
                    options={districtOptions}
                    {...districtRegistration}
                  />
                </div>
                <div className={styles.postalCode}>
                  <Input
                    control={control}
                    name="postalCode"
                    placeholder="郵遞區號"
                    inputClassName={styles.form_input}
                  />
                </div>
              </div>
              <div className={styles.row} style={{ marginBottom: 0 }}>
                <div className={styles.form_title} />
                <div className={styles.address}>
                  <Input
                    control={control}
                    name="address"
                    placeholder="街道, 巷弄, 門號, 樓層"
                    inputClassName={styles.form_input}
                  />
                </div>
              </div>
            </>
            )
          }
        </form>
      </fieldset>
    </div>
  );

}

Form.defaultProps = {
  onSubmit: () => {},
  values: {},
  disabled: false
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  disabled: PropTypes.bool
};

export default Form;
