import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import { Theme } from 'src/components/Modal/themes';
import UserService from 'src/services/user';
import ReceiptPage from 'src/pages/ReceiptPage';
import { delay } from 'src/utils';

import Model, { Status } from './ShoppingItem/model';
import ShoppingItem from './ShoppingItem';
import styles from './styles.module.css';

export const Identity = {
  consumer: 'consumer',
  sales: 'sales'
};

export default function ShoppingList({ buildings, identity, orders, onDelete }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [nowDownLoadingID, setID] = useState(0);
  const { state, actions } = useAppStateContext();

  const downloadReceipt = async (ev, oid) => {
    try {
      if (loading) { return; } // ! Note: must before `stopPropagation`

      ev.stopPropagation();
      setLoading(true);
      setID(oid);

      const receipt = await UserService.getOrderReceipt(state.profile.id, oid);

      const doc = (<ReceiptPage receipt={receipt} profile={state.profile} />);
      const asPdf = pdf([]); // [] is important, throws without an argument
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();

      await Promise.all([
        saveAs(blob, 'receipt.pdf'),
        delay(2000) // prevent double click
      ]);

    } catch (err) {
      console.log('downloadReceipt', err);

    } finally {
      setLoading(false);
      setID(0);
    }
  };

  const deleteOrder = (evt, id) => {
    evt.stopPropagation();
    actions.showModal({
      theme: Theme.warning,
      title: 'INFO',
      content: '您即將刪除此筆資料\n請確認是否執行',
      cancelText: '先不要',
      okText: '刪除',
      onOk: async () => {
        await onDelete(id);
      },
      onCancel: () => {}
    });
  };

  const createItem = (order) => {
    switch (order.orderType) {
      case Status.sold:
      case Status.pending:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            disabled={loading}
            onDownload={(ev) => downloadReceipt(ev, order.id)}
            nowDownLoadingID={nowDownLoadingID}
            onClick={() => {
              history.push(`/order/${order.id}`);
            }}
          />
        );
      case Status.notSold:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            disabled={loading}
            onDownload={(ev) => downloadReceipt(ev, order.id)}
            nowDownLoadingID={nowDownLoadingID}
            onClick={() => {
              if (identity === Identity.sales) {
                history.push(`/order/${order.id}`);
              }
            }}
          />
        );
      case Status.abandon:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            disabled={loading}
            onDownload={(ev) => downloadReceipt(ev, order.id)}
            nowDownLoadingID={nowDownLoadingID}
            // onDelete={(e) => {
            //   deleteOrder(e, order.id);
            // }}
            onClick={() => {
              if (identity === Identity.sales) {
                history.push(`/order/${order.id}`);
              }
            }}
          />
        );
      case Status.payment:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            onDelete={(e) => {
              deleteOrder(e, order.id);
            }}
            onClick={() => {
              history.push(`/payment/${state.profile.id}/${order.id}`);
            }}
          />
        );
      case Status.selecting:
        return (
          <ShoppingItem
            name={state.plan.name}
            item={order}
            key={order.id}
            onDelete={(e) => {
              deleteOrder(e, order.id);
            }}
            onClick={() => {
              history.push(`/preorder/${order.id}`);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>我的購物車</div>
      {
        orders.map((order) => createItem(Model.fromRes(order, buildings)))
      }
    </div>
  );
}

ShoppingList.defaultProps = {
  orders: [],
  identity: 'consumer',
  onDelete: () => {},
  buildings: []
};

ShoppingList.propTypes = {
  orders: PropTypes.array,
  identity: PropTypes.string,
  onDelete: PropTypes.func,
  buildings: PropTypes.array
};
