import React from 'react';
import { observer } from 'mobx-react';
import QRCode from 'qrcode.react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import CustomizeModal from '..';

import styles from './styles.module.css';

@observer
class QrCodeModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { viewModel } = this.props;
    return (
      <CustomizeModal
        className={clsx(styles.modal, viewModel.isShow ? styles.show : styles.hide)}
        hideModal={viewModel.hideModal}
      >
        <div
          className={styles.qrCodeContainer}
        >
          <QRCode
            value={viewModel.phoneNum}
            size={200}

          />
          <div className={styles.text}>
            手機掃描可直接撥號
          </div>
        </div>
      </CustomizeModal>
    );
  }
}

QrCodeModal.propTypes = {
  viewModel: PropTypes.object
};

QrCodeModal.defaultProps = {
  viewModel: null
};


export default QrCodeModal;
