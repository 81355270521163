import UserService from 'src/services/user';
import { Theme } from 'src/components/Modal/themes';
import ErrorMessageService from 'src/services/errorMessage';

export default class EcPay {

  didMount = async (props) => {
    await this.createForm(props);
  }

  createForm = async (props) => {
    const { history, profile, match, location, appContext } = props ?? {};

    try {
      const price = new URLSearchParams(location.search).get('price');

      const form = await UserService.createEcPayForm(match.params.uid, match.params.pid, price);

      document.open();
      document.write(form);
      document.close();

    } catch (err) {

      appContext.actions.showModal({
        theme: Theme.warning,
        title: 'INFO',
        content: ErrorMessageService.createEcPayForm(err),
        okText: '確定',
        onOk: () => {
          // TODO 待討論
          if (profile) {
            history.replace(`/payment/${profile.id}/${match.params.pid}`);
          } else {
            history.push('/home');
          }
        },
        onCancel: () => {
          // TODO 待討論
          if (profile) {
            history.replace(`/payment/${profile.id}/${match.params.pid}`);
          } else {
            history.push('/home');
          }
        }
      });
    }
  }
}
