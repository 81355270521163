import Profile from './Profile';

export default class Signup {
  constructor(params) {
    const { profile } = params ?? {};

    this.profile = Profile.fromRes(profile) ?? {};
  }

  static fromRes(data) {
    return new Signup(data);
  }
}
