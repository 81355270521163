import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

import PxCode from './P001';

@observer
class SectionA extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.sectionContainer}>
        <PxCode
          background={viewModel.background}
          subtitle1={viewModel.subtitle1}
          subtitle2={viewModel.subtitle2}
          casetitle={viewModel.casetitle}
          description1={viewModel.description1}
          description2={viewModel.description2}
          description3={viewModel.description3}
          description4={viewModel.description4}
          description1Unit={viewModel.description1Unit}
          description2Unit={viewModel.description2Unit}
          description3Unit={viewModel.description3Unit}
          description4Unit={viewModel.description4Unit}
          intro1={viewModel.intro1}
          intro2={viewModel.intro2}
          intro3={viewModel.intro3}
          intro4={viewModel.intro4}
          shoppingbutton={viewModel.shoppingbutton}
        />
      </div>
    );
  }
}

SectionA.defaultProps = {
  viewModel: {}
};

SectionA.propTypes = {
  viewModel: PropTypes.object
};


export default SectionA;
