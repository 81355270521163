import React from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { createPopup } from './Factory';

import './customSwiper.css';
import styles from './styles.module.css';
import closeImg from '../../assets/btn_close2@btnClose.png';

SwiperCore.use([Mousewheel, Navigation]);

@observer
class Popup extends React.Component {
  render() {
    const { popupListVm } = this.props;
    return (
      <>
        {
          popupListVm.showStatus
            ? (
              <div className={clsx(styles.popup)}>
                <div className={styles.popupBg} />
                <Swiper
                  onSwiper={popupListVm.onSwiper}
                  className={styles.carousel}
                  onActiveIndexChange={popupListVm.onActiveIndexChange}
                  slidesPerView="auto"
                  centeredSlides
                  spaceBetween="5%"
                  mousewheel={{
                    forceToAxis: true
                  }}
                  resizeObserver
                  navigation={{
                    prevEl: '.slidePre',
                    nextEl: '.slideNext'
                  }}
                >
                  {popupListVm.popupList.map((popup, index) => (
                    <SwiperSlide
                      key={index}
                      className={styles.slide}
                    >
                      {createPopup(popup)}
                    </SwiperSlide>
                  ))}
                  <div className={clsx(
                    styles.slide_pre,
                    'slidePre',
                    popupListVm.popupList.length === 1 && styles.hide,
                    popupListVm.activeIndex === 0 && styles.hide
                  )}
                  />
                  <div className={clsx(
                    styles.slide_next,
                    'slideNext',
                    popupListVm.popupList.length === 1 && styles.hide,
                    popupListVm.isLastSlide && styles.hide
                  )}
                  />
                </Swiper>
                <div
                  className={styles.closeBtn}
                  onClick={popupListVm.closePopup}
                >
                  <img src={closeImg} alt="close" />
                </div>
              </div>
            )
            : null
        }
      </>
    );
  }
}

Popup.propTypes = {
  popupListVm: PropTypes.object
};

Popup.defaultProps = {
  popupListVm: null
};


export default Popup;

