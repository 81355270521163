/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useCountDown from 'react-countdown-hook';

import UserService from 'src/services/user';
import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import useProfile from 'src/hooks/useProfile';
import Popup from 'src/components/Popup';
import PopupsViewModel from 'src/viewModels/Popup/PopupsViewModel';
import { privacys, convertTelephoneText } from 'src/constants';
import Footer from 'src/components/Footer';
import { Theme } from 'src/components/Modal/themes';
import ErrorMessageService from 'src/services/errorMessage';

import OtpForm from 'src/components/OtpForm';
import LoginForm from './components/LoginForm';
import styles from './styles.module.css';

const InitialTime = 300 * 1000;
const Interval = 1000;

export default function LoginPage() {
  const { actions } = useAppStateContext();
  const history = useHistory();
  const prePath = history.location?.state?.target ?? '/center';
  const [timeLeft, { start }] = useCountDown(InitialTime, Interval);
  const [step, setStep] = useState('step1');
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState('');
  const popupVm = useRef(new PopupsViewModel(privacys));

  const profile = useProfile(false);

  useEffect(
    () => {
      if (profile) {
        history.replace('/center');
      }
    },
    [profile, history]
  );

  const handleError = async (cb, errorService) => {
    try {
      await cb();
    } catch (err) {
      actions.showModal({
        theme: Theme.error,
        title: 'ERROR',
        content: errorService(err),
        okText: '確認',
        onOk: () => {}
      });
    }
  };

  const onLoginClick = async (data) => {
    setLoading(true);
    await handleError(async () => {
      const { phone } = data;
      const number = convertTelephoneText(phone);
      await UserService.otp(number);
      setId(number);
      setStep('step2');
      start();
    }, ErrorMessageService.otp);
    setLoading(false);
  };

  const onOtpClick = async (data) => {
    setLoading(true);
    await handleError(async () => {
      const { otp } = data;
      const res = await UserService.login(id, otp);
      actions.updateProfile(res.profile);
      history.replace(prePath);
    }, ErrorMessageService.login);
    setLoading(false);
  };

  const onTimeUp = async () => {
    await handleError(async () => {
      await UserService.otp(id);
      start();
    }, ErrorMessageService.otp);
  };

  return (
    <div className="page">
      {
        step === 'step1' ? (
          <LoginForm
            onSubmit={onLoginClick}
            loading={loading}
            prePath={prePath}
          />
        ) : (
          <div className={styles.content}>
            <div className={styles.form_layout}>
              <h2 className={styles.topic}>驗證</h2>
              <OtpForm
                onSubmit={onOtpClick}
                seconds={timeLeft / 1000}
                onTimeUp={onTimeUp}
                loading={loading}
                btnText="登入"
              />
              {/* TODO: need further check. */}
              {/* <div className={styles.signup}>
                還沒有帳號？
                <Link to="/signup">註冊</Link>
              </div> */}
            </div>
          </div>
        )
      }
      <Footer>
        <span>2021 subtle© All Rights Reserved. v1.01</span>
        <span className={styles.privacy} onClick={popupVm.current.showPopup}>隱私權政策</span>
      </Footer>
      <Popup popupListVm={popupVm.current} />
    </div>
  );
}
