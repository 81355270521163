import { observable, action, computed, makeObservable } from 'mobx';
import diff from 'deep-diff';

import UserService from 'src/services/user';
import PlansService from 'src/services/plans';
import { privacys } from 'src/constants';
import PopupsViewModel from 'src/viewModels/Popup/PopupsViewModel';
import { Theme } from 'src/components/Modal/themes';

export default class CenterViewModel {
  @observable orders = [];
  @observable profile = null;
  @observable formDisabled = true;
  @observable buildings;

  popupVm = new PopupsViewModel(privacys);

  unblockNavigation = null;

  constructor(props) {
    makeObservable(this);
    this.props = props;
  }

  @computed get name() {
    return this.profile ? this.profile.lastName + this.profile.firstName : '';
  }

  @action didMount = (props) => {
    const { history, profile } = props ?? {};

    this.unblockNavigation = history.block((location) => {
      this.unblockNavigation();

      return true;
    });

    this.init(profile);

  }

  @action didUpdate = (prevProps, props) => {
    if (diff(prevProps.profile, props.profile)) {
      this.init(props.profile);
    }
  }

  @action init = async (profile) => {
    this.orders = await UserService.getOrders(profile.id);
    this.buildings = await PlansService.getBuildings();
    this.profile = profile;
  }

  @action updateOrder = async (id) => {
    await UserService.deletePreOrder(this.profile.id, id);
    // TODO: change with filter.
    this.orders = await UserService.getOrders(this.profile.id);
  }

  @action onDisabledChange = (disabled) => {
    this.formDisabled = disabled;
    const { appContext, history } = this.props ?? {};

    if (disabled) {
      this.unblockNavigation = history.block((location) => {
        this.unblockNavigation();
        return true;
      });
    } else {
      this.unblockNavigation = history.block((location) => {
        if (location.pathname === '/center') {
          return true;
        }

        if (location.pathname === '/login') {
          this.navigate(history, location.pathname);
          return true;
        }

        appContext.actions?.showModal({
          theme: Theme.warning,
          title: 'INFO',
          content: '您尚未儲存個人資料\n離開頁面將會放棄編輯進度\n請確認是否執行',
          okText: '確認',
          cancelText: '先不要',
          onOk: () => {
            this.navigate(history, location.pathname);
          },
          onCancel: () => {}
        });

        return false;
      });
    }
  }

  navigate = (history, to, params) => {
    this.unblockNavigation?.();
    history.push(to, params);
  };
}
