import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import ReactYouTube from 'react-youtube';

import { getYouTubeId } from 'src/utils';
import styles from './styles.module.css';


function YouTube(props, ref) {
  const { value, ...others } = props;
  const youTubeRef = useRef();

  const videoId = getYouTubeId(value);

  const opts = {
    width: '100%',
    height: '100%',
    playerVars: { autoplay: 1, mute: 1, loop: 1, rel: 0, playlist: videoId }
  };
  useImperativeHandle(
    ref,
    () => ({
      onEnter() {
        youTubeRef.current.internalPlayer.playVideo();
      },
      onLeave() {
        youTubeRef.current.internalPlayer.pauseVideo();
      },
      onReset() {
        youTubeRef.current.internalPlayer.seekTo(0);
      }
    }),
    []
  );

  const _onReady = (event) => {
    // workaround ，第一次載入 youtube 影片無法使用 playVideo() 播放，
    // 需要透過用戶點擊影片才能播，所以利用 autoplay 設定播放後再去暫停
    event.target.pauseVideo();
  };

  return (
    <ReactYouTube containerClassName={styles.container_layout} ref={youTubeRef} onReady={_onReady} opts={opts} {...others} />
  );
}

export default forwardRef(YouTube);
