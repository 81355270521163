/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Px, commonStyles } from '../../posize';
import arrowImg from '../../../../../assets/goShoppingArrow.png';

export default function Page_001(props) {
  return (
    <div className={`page-001 ${css(styles.group, styles.group_layout)}`}>
      <Px.div
        x="186px 1548fr 186px"
        y="minmax(55px, 75fr) minmax(0px, max-content) 75fr"
        className={css(styles.flex)}
        xxlX="93px 1548fr 93px"
        xxlY="minmax(55px, 38fr) minmax(0px, max-content) 38fr"
        xlX="47px 1548fr 47px"
        xlY="minmax(55px, 25fr) minmax(0px, max-content) 25fr"
        lgX="24px 1548fr 24px"
        lgY="minmax(55px, 25fr) minmax(0px, max-content) 25fr"
        mdX="10px 1548fr 10px"
        mdY="minmax(55px, 25fr) minmax(0px, max-content) 25fr"
        smX="10px 1548fr 10px"
        smY="minmax(55px, 25fr) minmax(0px, max-content) 25fr"
      >
        <div className={css(styles.flex_spacer)} />
        <div className={css(styles.flex_col)}>
          <Px.div
            x="0px 474fr 0px"
            y="355fr minmax(0px, max-content) 255fr"
            className={css(styles.flex1)}
            mdX="0px 474fr 0px"
            mdY="355fr minmax(0px, max-content) 255fr"
            xsX="0px 474fr 0px"
            xsY="178fr minmax(0px, max-content) 128fr"
          >
            <div className={css(styles.flex1_row)}>
              <div className={css(styles.group1, styles.group1_layout)}>
                <div className={css(styles.labelBox)}>
                  <div className={css(styles.labeleg, styles.labeleg_layout)}>
                    {props.labeleg}
                  </div>
                  <div
                    style={{ '--color': props.label }}
                    className={css(styles.label, styles.label_layout)}
                  />
                </div>
              </div>
            </div>
            <h3 className={css(styles.labelct, styles.labelct_layout)}>
              {props.labelct}
            </h3>
            <Px.h1
              x="4px 412fr 58fr"
              y="0px minmax(0px, 44fr) 0px"
              className={css(styles.title)}
              xsX="4px 412fr 58fr"
              xsY="25px minmax(0px, 44fr) 0px"
            >
              {props.title}
            </Px.h1>
            <h5
              className={css(styles.description, styles.description_layout)}
            >
              {props.description}
            </h5>
            <div className={css(styles.flex1_row)}>
              <Link
                to="/pickout"
                className={css(styles.cover_group, styles.cover_group_layout)}
                style={{
                  borderRadius: props.shoppingbutton.curveFillet,
                  backgroundColor: props.shoppingbutton.color
                }}
              >
                <div
                  className={css(styles.flex2, styles.flex2_layout)}
                >
                  <div className={css(styles.flex2_spacer)} />
                  <h4
                    className={css(
                      styles.highlights,
                      styles.highlights_layout
                    )}
                  >
                    {props.shoppingbutton.text}
                  </h4>
                  <div className={css(styles.flex2_spacer1)} />
                  <div className={css(styles.flex2_col)}>
                    <Px.div
                      style={{
                        '--src': `url(${arrowImg})`
                      }}
                      x="0px 51.5px 0px"
                      y="9fr minmax(8.68px,8.68fr) 8.32fr"
                      className={css(styles.img)}
                      xxlX="0px 45.5px 0px"
                      xxlY="9fr minmax(8.68px,8.68fr) 8.32fr"
                      xlX="0px 38px 0px"
                      xlY="9fr minmax(8.68px,8.68fr) 8.32fr"
                      lgX="0px 26px 0px"
                      lgY="9fr minmax(8.68px,8.68fr) 8.32fr"
                    />
                  </div>
                </div>
              </Link>
            </div>
          </Px.div>
        </div>
        <div className={css(styles.flex_spacer1)} />
        <div className={css(styles.flex_col1)}>
          <div
            className={css(styles.picture, styles.picture_layout)}
          >
            {props.carousel}
            {props._renderPreBtn}
            {props._renderNextBtn}
          </div>
        </div>
      </Px.div>
    </div>
  );
}

Page_001.inStorybook = true;

const styles = StyleSheet.create({
  group: {
    display: 'flex',
    backgroundColor: 'rgb(255,255,255)'
  },
  group_layout: {
    position: 'absolute',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    left: 0,
    flexGrow: 1,
    right: 0
  },
  flex: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  flex_spacer: {
    display: 'flex',
    flex: '0 1 4px'
  },
  flex_col: {
    display: 'flex',
    flex: '1 1 474px'
  },
  flex1: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  flex1_row: {
    display: 'flex'
  },
  group1: {
    display: 'flex'
  },
  group1_layout: {
    position: 'relative',
    minHeight: 14,
    margin: 0
  },
  labelBox: {
    position: 'relative'
  },
  label: {
    backgroundColor: 'var(--color)',
    width: '100%',
    height: '100%'
  },
  label_layout: {
    position: 'absolute',
    height: 6,
    bottom: 1,
    left: 0,
    zIndex: 0
  },
  labeleg: {
    display: 'flex',
    justifyContent: 'flex-end',
    font: '900 12px/1.2 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    textAlign: 'right',
    letterSpacing: '0px',
    zIndex: 1
  },
  labeleg_layout: {
    position: 'relative',
    flexGrow: 1
  },
  labelct: {
    font: '900 20px/1 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '14px',
      textAlign: 'left'
    }
  },
  labelct_layout: {
    position: 'relative',
    margin: '0px 62px 0px 0px',
    '@media (max-width: 2999.98px)': {
      margin: '0px 62px 0px 4px'
    },
    '@media (max-width: 575.98px)': {
      margin: '25px 62px 0px 4px'
    }
  },
  title: {
    font: '900 32px/1.375 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '2px',
    width: '100%',
    height: '100%',
    '@media (max-width: 1199.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '25px',
      textAlign: 'left'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '20px',
      textAlign: 'left'
    },
    '@media (max-width: 767.98px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontSize: '20px',
      textAlign: 'left'
    }
  },
  description: {
    font: '16px/1.875 "Noto Sans TC", "Roboto", serif',
    color: 'rgb(41,41,41)',
    letterSpacing: '1px',
    margin: 0,
    whiteSpace: 'pre-wrap'
  },
  description_layout: {
    position: 'relative',
    margin: '11px 0px 0px 4px',
    '@media (max-width: 991.98px)': {
      margin: '11px 0px 0px 4px'
    },
    '@media (max-width: 575.98px)': {
      margin: '25px 0px 0px 10px'
    }
  },
  cover_group: {
    flex: '0 1 300px',
    backgroundColor: 'rgb(41,41,41)',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.7'
    }
  },
  cover_group_layout: {
    position: 'relative',
    overflow: 'visible',
    width: 'fit-content',
    margin: '85px 0px 0px',
    '@media (max-width: 1199.98px)': {
      width: 'fit-content',
      margin: '30px 0px 0px'
    },
    '@media (max-width: 991.98px)': {
      width: 'fit-content',
      margin: '30px 0px 0px'
    },
    '@media (max-width: 575.98px)': {
      width: 'fit-content',
      margin: '43px 0px 0px'
    }
  },
  flex2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flex2_layout: {
    position: 'relative',
    overflow: 'visible',
    width: '300px',
    margin: '12px 0px'
  },
  flex2_spacer: {
    display: 'flex',
    flex: '0 0 auto'
  },
  highlights: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    font: '18px "Noto Sans TC", "Roboto", serif',
    color: 'rgb(255,255,255)',
    textAlign: 'center',
    letterSpacing: '5.625px',
    '@media (max-width: 1919.98px)': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    '@media (max-width: 1399.98px)': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    '@media (max-width: 1199.98px)': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    },
    '@media (max-width: 991.98px)': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  highlights_layout: {
    position: 'relative',
    maxWidth: '90%',
    flex: '0 0 185px',
    margin: 0
  },
  flex2_spacer1: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: 0.5
  },
  flex2_col: {
    display: 'flex',
    flex: '0 0 43.5px'
  },
  img: {
    background: 'var(--src) center center / contain no-repeat',
    width: '100%',
    height: '100%'
  },
  flex_spacer1: {
    display: 'flex',
    flex: '0 1 31px'
  },
  flex_col1: {
    position: 'relative',
    display: 'flex',
    flex: '0 1 1039px'
  },
  picture: {
    width: '100%',
    paddingTop: '73.33%'
  },
  picture_layout: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    flexGrow: 1,
    margin: 0
  }
});

Page_001.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  labeleg: PropTypes.string,
  labelct: PropTypes.string,
  description: PropTypes.string,
  shoppingbutton: PropTypes.object,
  carousel: PropTypes.element,
  _renderNextBtn: PropTypes.element,
  _renderPreBtn: PropTypes.element
};

Page_001.defaultProps = {
  title: null,
  label: null,
  labeleg: null,
  labelct: null,
  description: null,
  shoppingbutton: null,
  carousel: null,
  _renderNextBtn: null,
  _renderPreBtn: null
};
