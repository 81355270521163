import Floor from './Floor';

export default class Building {
  constructor(params) {
    const { id, name, announcement, floors } = params ?? {};

    this.id = id;
    this.name = name;
    this.announcement = announcement ?? '';
    this.floors = floors?.map((floor) => Floor.fromRes(floor)) ?? [];
  }

  get isDisable() {
    return this.floors.every((floor) => !floor.isActive || floor.isDisable);
  }

  get isActive() {
    return this.floors.some((floor) => floor.isActive);
  }

  static fromRes(data) {
    return new Building(data);
  }
}
