import React from 'react';
import { observable, makeObservable, action, computed } from 'mobx';

import AuthService from 'src/services/auth';

import QrCodeModalViewModel from './CustomizeModal/QrCodeModalViewModel';
import AccountInfoModalViewModel from './CustomizeModal/AccountInfoModalViewModel';

export default class ConversationModuleViewModel {
  @observable enable = false;
  @observable visible = false;
  @observable unread = 0;
  @observable token = '';
  @observable groupId;
  @observable targetId = '';
  @observable client = React.createRef();
  @observable userIdentity = null;
  @observable popupVm = null;
  @observable isLoading = true;
  @observable isSmall = window.innerWidth < 768;


  constructor() {
    makeObservable(this);

    this.initGroupId();
  }

  @action
  initGroupId = () => {
    // local
    if (/:\d{4,}$/.test(window.location.origin)) {
      const r = window.location.origin.split(/:\d{4,}/);
      if (r[0]) {
        this.groupId = `${r[0]}:5000`;
      }
      return;
    }

    // dev
    if (/^dev-/.test(window.location.host)) {
      this.groupId = 'dev-admin';
      return;
    }

    // pro
    this.groupId = 'admin';
  }

  @action
  didUpdate = (profile) => {
    const token = AuthService.getToken();
    this.updateUserIdentity(profile.group);
    this.start(token);
  }

  @action
  willUnmount = () => {
    this.end();
  }

  @action
  generatePopupVm = () => {
    if (this.userIdentity === 'consumer') {
      this.popupVm = new QrCodeModalViewModel();
      return;
    }
    this.popupVm = new AccountInfoModalViewModel();
  }

  @action
  updateUserIdentity = (identity) => {
    this.userIdentity = identity;
    this.generatePopupVm();
  }

  @action
  handleChangeToSmall = (e) => {
    this.isSmall = e;
  }

  // @action
  // handleWheel = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // }

  // @action
  // handleTouch = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // }

  // @action
  // handleAddEventListener = () => {
  //   const nowPage = document.querySelector('.page');
  //   nowPage?.addEventListener('wheel', this.handleWheel, { passive: false });
  //   nowPage?.addEventListener('touchstart', this.handleTouch, { passive: false });
  // }

  // @action
  // handleRemoveEventListener = () => {
  //   const nowPage = document.querySelector('.page');
  //   nowPage?.removeEventListener('wheel', this.handleWheel);
  //   nowPage?.removeEventListener('touchstart', this.handleTouch);
  // }

  // comm func

  @action
  show = () => {
    this.setVisible(true);
  };

  @action
  hide = () => {
    this.setVisible(false);
  };

  @action
  chat = (targetId) => {
    this.setVisible(true);
    this.client.current?.chat(targetId);
  };

  @action
  openLatest = () => {
    this.setVisible(true);
    this.client.current?.openLatest();
  };

  @action
  openAnnouncement = () => {
    this.setVisible(true);
    this.client.current?.openAnnouncement();
  };

  @action
  start = (token) => {
    if (token) {
      this.token = token;
      this.enable = true;
    }
  };

  @action
  end = () => {
    if (this.visible) {
      this.setVisible(false);
    }
    this.enable = false;
    this.unread = 0;
  };

  @action
  setVisible = (visible) => {
    const nowPage = document.querySelector('.page');
    if (visible && this.isSmall) {
      nowPage.classList.add('disablePull');
    } else if (!visible && this.isSmall) {
      nowPage.classList.remove('disablePull');
    }
    console.log('nowPage', nowPage);
    this.visible = visible;
  };

  @action
  onError = (err) => {
    console.log('onError', err);
  };

  @action
  onLoad = () => {
    this.isLoading = false;
  };

  @action
  onClose = () => {
    this.setVisible(false);
  };

  @action
  onUnreadCountChanged = (count) => {
    // console.log('onUnreadCountChanged', count);
    this.unread = count;
  };

  @action
  onAnnouncementReceived = () => {
    // console.log('onAnnouncementReceived');
    if (!this.state.visible) {
      this.openAnnouncement();
    }
  };

  @action
  onVideoCallIncoming = () => {
    // console.log('onVideoCallIncoming');
    this.setVisible(true);
  };

  @action
  onCartClicked = async (target) => {
    try {
      const targetId = target.split('@')[1];
      await this.popupVm.updateUserInfo(targetId);
      this.popupVm.showModal();
    } catch (error) {
      console.error(error);
    }
  };

  @action
  onVoiceClicked = async (target) => {
    try {
      const targetId = target.split('@')[1];
      await this.popupVm.getSalesPhoneNumber(targetId);
    } catch (error) {
      console.error(error);
    }

  };
}
