import React from 'react';
import { observer } from 'mobx-react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import clsx from 'clsx';

import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';

import styles from './styles.module.css';

import chatGif from '../../assets/chatting.gif';
import chatPng from '../../assets/chatting.png';

@observer
class CsBtn extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { unread, openModalFunction } = this.props;
    const chatImg = unread !== 0 ? chatGif : chatPng;
    const userIdentity = this.props.appContext.state.profile?.group;
    return (
      <>
        <MediaQuery minWidth={768}>
          <div className={styles.modal}>
            {
              userIdentity !== 'sales' && (
                <div className={clsx(styles.outerContainer)}>
                  <div
                    className={styles.csBtnContainer}
                    onClick={userIdentity === 'consumer'
                      ? openModalFunction
                      : () => this.props.history.push('/signup', { target: this.props.location.pathname })}
                  >
                    <img
                      className={clsx(styles.chatting, unread !== 0 && styles.chattingGif)}
                      src={chatImg}
                      alt="csBtn"
                    />
                  </div>
                </div>
              )
            }
          </div>
        </MediaQuery>
      </>
    );
  }
}

CsBtn.propTypes = {
  unread: PropTypes.number,
  appContext: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  openModalFunction: PropTypes.func
};

CsBtn.defaultProps = {
  unread: null,
  appContext: null,
  history: null,
  location: null,
  openModalFunction: () => {}
};

export default withAppStateContext(withRouter(CsBtn));
