import Profile from './Profile';

export default class Login {
  constructor(params) {
    const { profile, token } = params ?? {};

    this.profile = Profile.fromRes(profile) ?? {};
    this.token = token ?? '';
  }

  static fromRes(data) {
    return new Login(data);
  }
}
