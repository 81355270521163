import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Beforeunload } from 'react-beforeunload';
import clsx from 'clsx';

import CenterViewModel from 'src/viewModels/Center';
import withProfile from 'src/components/withProfile';
import Footer from 'src/components/Footer';
import Popup from 'src/components/Popup';
import otherJSON from 'src/json/other.json';
import withAppStateContext from 'src/components/AppStateProvider/withAppStateContext';
import styles from './styles.module.css';
import ShoppingList from './components/ShoppingList';
import UserCard from './components/UserCard';

@observer
class Center extends Component {
  constructor(props) {
    super(props);

    this.viewModel = new CenterViewModel(props);
  }

  componentDidMount() {
    this.viewModel.didMount(this.props);
  }

  componentDidUpdate(prevProps) {
    this.viewModel.didUpdate(prevProps, this.props);
  }

  render() {
    return this.viewModel.profile ? (
      <div className={clsx('page', styles.layout)}>
        <div className={styles.user_layout}>
          <div className={styles.header}>
            <span className={styles.header_bold}>HELLO,&nbsp;</span>
            <span className={styles.header_username}>{this.viewModel.name}</span>
          </div>
          <div className={styles.user}>
            <UserCard profile={this.viewModel.profile} onDisabledChange={this.viewModel.onDisabledChange} />
          </div>
          <a className={styles.advertisement} href={otherJSON.center.link} target="_blank" rel="noreferrer">
            <img width={261} height="100%" style={{ borderRadius: 15 }} src={otherJSON.center.advertise} alt="advertisement" />
          </a>
        </div>
        <ShoppingList buildings={this.viewModel.buildings} orders={this.viewModel.orders} identity={this.viewModel.profile.group} onDelete={this.viewModel.updateOrder} />
        <Footer>
          <span>2021 subtle© All Rights Reserved. v1.01</span>
          <span className={styles.privacy} onClick={this.viewModel.popupVm.showPopup}>隱私權政策</span>
        </Footer>
        <Popup popupListVm={this.viewModel.popupVm} />
        {!this.viewModel.formDisabled && <Beforeunload onBeforeunload={() => 'You’ll lose your data!'} />}
      </div>
    ) : null;
  }
}

export default withProfile(withAppStateContext(withRouter(Center)));
