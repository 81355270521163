import React from 'react';
import { Card, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import soldIcon from 'src/assets/center_sold.png';
import timeIcon from 'src/assets/center_time.png';
import downloadIcon from 'src/assets/center_download.png';

import styles from './styles.module.css';
import { Status } from './model';



function ShoppingItem({ name, item, onClick, onDelete, onDownload, nowDownLoadingID, disabled }) {
  return (
    <Card
      hoverable
      onClick={disabled ? null : onClick}
      className={styles.shopping_card}
      bodyStyle={{ padding: '3px 20px 20px 20px' }}
      cover={(
        <div className={styles.card_cover}>
          <img
            src={item.img}
            width="50"
            className={styles.card_img}
            style={{ borderTopRightRadius: 15, borderTopLeftRadius: 15 }}
            alt="shopping_item"
          />
          <div className={styles.card_tag}>
            {item.orderType === Status.sold && <img src={soldIcon} width={30} height={30} alt="sold" style={{ marginLeft: -3 }} />}
            <span className={styles.tag_text}>{item.tag}</span>
          </div>
          {onDelete && <div className={styles.delete} onClick={disabled ? null : onDelete} />}
        </div>
      )}
    >
      <div className={styles.card_title_layout}>
        <span className={styles.card_title}>{name}</span>
        <div className={styles.time}>
          <img src={timeIcon} width={10} height={10} alt="time" />
          <span className={styles.time_text}>{item.updateTime}</span>
        </div>
      </div>
      <div className={styles.price}>{item.totalCurrency}</div>
      <div className={styles.detail_container}>
        <div className={styles.household}>{`${item.household}, ${item.remarks[0]}, ${item.remarks[1]}, ${item.remarks[2]}`}</div>
        {
          item.type === 'order' && (
            <div className={styles.oid}>
              訂單編號：
              {item.id}
            </div>
          )
        }
      </div>
      {
        onDownload && (
          <div className={clsx(styles.download, disabled && styles.disable)} onClick={disabled ? null : onDownload}>
            <img width={20} height={20} src={downloadIcon} alt="download" />
            <span className={styles.download_text}>下載訂單資料</span>
            {
              nowDownLoadingID === item.id && (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />
              )
            }
          </div>
        )
      }
    </Card>
  );
}

ShoppingItem.defaultProps = {
  item: {},
  onDelete: null,
  onDownload: null,
  onClick: null,
  name: '',
  disabled: false,
  nowDownLoadingID: 0
};

ShoppingItem.propTypes = {
  item: PropTypes.object,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  nowDownLoadingID: PropTypes.number
};

export default ShoppingItem;
