import React from 'react';

import { PopupType } from 'src/constants';
import PopupPicText from 'src/components/Popup/Types/PopupPicText';
import PopupText from 'src/components/Popup/Types/PopupText';
import PopupPic from 'src/components/Popup/Types/PopupPic';

function createSlideView(viewModel) {
  const { type } = viewModel;
  switch (type) {
    case PopupType.TypePicText:
      return <PopupPicText viewModel={viewModel} />;
    case PopupType.TypePic:
      return <PopupPic viewModel={viewModel} />;
    case PopupType.TypeText:
      return <PopupText viewModel={viewModel} />;
    default:
      throw new Error(`Can not find ${type}`);
  }
}

export { createSlideView };
