/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import UserService from 'src/services/user';
import useAppStateContext from 'src/components/AppStateProvider/useAppStateContext';
import changeIcon from 'src/assets/center_change.png';
import editIcon from 'src/assets/center_edit.png';
import { avatars } from 'src/constants';
import { Theme } from 'src/components/Modal/themes';

import Form from './Form';
import AvatarModal from './AvatarModal';
import styles from './styles.module.css';

export default function UserCard({ profile, onDisabledChange }) {
  const history = useHistory();
  const [formDisabled, setDisabled] = useState(true);
  const [modalVisible, setVisible] = useState(false);
  const [activeAvatar, setAvatar] = useState(profile?.avatar ?? 0);
  const [loading, setLoading] = useState(false);
  const { actions } = useAppStateContext();

  useEffect(() => {
    if (!profile) {
      setLoading(true);

    } else {
      setLoading(false);
      setAvatar(profile.avatar);
    }
  }, [profile]);

  const onEdit = () => {
    setDisabled(false);
    onDisabledChange(false);
  };

  const onSave = async (data) => {
    const user = await UserService.updateProfile(profile?.id, data);
    actions.updateProfile(user);
    setDisabled(true);
    onDisabledChange(true);
  };

  const updateAvatar = async (index) => {
    const data = await UserService.updateProfile(profile?.id, { avatar: index });
    actions.updateProfile(data);
    setAvatar(index);
    setVisible(false);
  };

  const logout = async () => {
    try {
      actions.showModal({
        theme: Theme.warning,
        title: 'INFO',
        content: '確定要登出嗎?',
        cancelText: '先不要',
        okText: '確定',
        onOk: async () => {
          await UserService.logout();
          actions.updateProfile(null);
          history.push('/login');
        },
        onCancel: () => {}
      });
    } catch (err) {
      console.log('logout error: ', err.response);
    }
  };

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
      <div className={styles.layout}>
        <div className={styles.content}>
          <div className={styles.edit_layout_mobile}>
            {
              formDisabled && profile?.group === 'consumer' && (
                <button type="button" className={styles.edit_btn} onClick={onEdit}>
                  <img className={styles.edit_img} src={editIcon} alt="editIcon" />
                  <span>修改</span>
                </button>
              )
            }
            {
              !formDisabled && profile?.group === 'consumer' && (
                <button form="userCardForm" type="submit" className={styles.edit_btn}>
                  <img className={styles.edit_img} src={editIcon} alt="editIcon" />
                  儲存
                </button>
              )
            }
          </div>
          <div className={styles.avatar_layout}>
            <div className={styles.avatar}>
              <img
                width={120}
                height={120}
                className={styles.avatar_img}
                src={avatars[activeAvatar]}
                alt="activeAvatar"
              />
              <img
                width={30}
                height={30}
                onClick={() => setVisible(true)}
                className={styles.avatar_change}
                src={changeIcon}
                alt="activeAvatar"
              />
            </div>
            <div className={styles.logout}>
              <span className={styles.logout_text} onClick={logout}>登出</span>
            </div>
          </div>
          <div className={styles.form_layout}>
            <div className={styles.edit_layout_pc}>
              {
                formDisabled && profile?.group === 'consumer' && (
                  <button type="button" className={styles.edit_btn} onClick={onEdit}>
                    <img className={styles.edit_img} src={editIcon} alt="editIcon" />
                    <span>修改</span>
                  </button>
                )
              }
              {
                !formDisabled && profile?.group === 'consumer' && (
                  <button form="userCardForm" type="submit" className={styles.edit_btn}>
                    <img className={styles.edit_img} src={editIcon} alt="editIcon" />
                    儲存
                  </button>
                )
              }
            </div>
            {
              profile ? (
                <Form values={profile} onSubmit={onSave} disabled={formDisabled} />
              ) : null
            }
          </div>
        </div>
        <AvatarModal
          selectedAvatar={profile?.avatar}
          avatars={avatars}
          visible={modalVisible}
          onOk={updateAvatar}
          onCancel={() => setVisible(false)}
        />
      </div>
    </Spin>
  );
}


UserCard.defaultProps = {
  profile: null,
  onDisabledChange: () => {}
};

UserCard.propTypes = {
  profile: PropTypes.object,
  onDisabledChange: PropTypes.func
};
