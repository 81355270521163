import React from 'react';
import { observable, makeObservable, action, computed } from 'mobx';

export default class IconBtnViewModel {
  @observable id = 0;
  @observable title = '';
  @observable iconImg = '';
  @observable xPercentage = 0;
  @observable yPercentage = 0;
  @observable targetPage = {
    page: '',
    slide: 0,
    open: false
  }
  constructor(props) {
    makeObservable(this);

    this.deserialize(props);
  }

  @action
  deserialize = (props) => {
    const {
      id,
      icon,
      lobby_icon: lobbyIcon,
      xPosition,
      yPosition,
      targetPage
    } = props;

    this.id = id;
    this.title = icon;
    this.iconImg = lobbyIcon;
    this.xPercentage = xPosition / 100;
    this.yPercentage = yPosition / 100;
    this.targetPage = targetPage;
  }

  @computed
  get target() {
    return this.targetPage.page.split('/')[1];
  }
}
