import { observable, makeObservable, action } from 'mobx';
import UserService from 'src/services/user';
import dayjs from 'dayjs';

import CustomizeModalViewModel from '.';

export default class AccountInfoModalViewModel extends CustomizeModalViewModel {
  @observable userInfo = {
    name: '',
    email: '',
    phone: '',
    purchase: []
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  updateUserInfo = async (targetId) => {
    try {
      const profile = await UserService.getUserProfile(targetId);
      const { firstName, lastName, email, telephone } = profile;
      const orders = await UserService.getOrders(targetId);
      const purchaseList = orders.map((order) => {
        const info = JSON.parse(order.ordererInfo);
        const door = order.door.name;
        const floor = order.door.floor.name;
        const building = order.door.floor.building.name;
        const buildingName = building + floor + door;
        const phone = info?.telephoneOptional ? `${info.telephoneRequired}, ${info.telephoneOptional}` : info?.telephoneRequired;
        return ({
          id: order.id,
          ordererName: order.ordererName,
          birthday: info?.birthday,
          gender: info?.gender,
          job: info?.job,
          status: this.getOrderCT(order),
          email: info?.email,
          // need to confirm.
          updatedAt: order.updatedAt,
          dateString: dayjs(order.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          phone,
          amount: order.door.price ?? order.price,
          building: buildingName,
          address: info?.fullAddress,
          style: order.style
        });
      });
      purchaseList.sort((a, b) => a.updatedAt - b.updatedAt > 1);
      this.userInfo = {
        name: lastName + firstName,
        email,
        phone: telephone,
        purchase: purchaseList
      };
    } catch (error) {
      console.error(error);
    }
  }

  @action
  getOrderCT = (order) => {
    if (order.type === 'order') {
      if (order.status === 'sold') {
        return '成交';
      }
      if (order.status === 'notSold') {
        return '無成交';
      }
      if (order.status === 'abandon') {
        return '已退訂';
      }
      if (order.status === 'pending') {
        if (order.queueIndex === 1) {
          return '第一順位';
        }
        if (order.queueIndex === 2) {
          return '第二順位';
        }
        if (order.queueIndex === 3) {
          return '第三順位';
        }
      }
    }
    if (!this.paymentType) {
      return '選戶中';
    }
    return '付款中';
  }

}
