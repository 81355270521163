import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';

import Receipt from 'src/models/responses/Receipt';
import Profile from 'src/models/responses/Profile';
import { group } from 'src/utils';

import Row from './components/Row';
import OrdererInfo from './models/OrdererInfo';

const ReceiptPage = (props) => {
  const { order, trade } = props.receipt ?? {};

  const profile = Profile.fromData(props.profile);
  const orderer = OrdererInfo.fromJSON(order?.ordererInfo);
  const infoPairs = group([
    {
      key: 'paymentType',
      label: '付款方式',
      value: order?.paymentTypeLabel
    },
    ...orderer.toFields()
  ], 2);

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <View style={styles.section}>
          <Text style={styles.title}>訂單資訊</Text>
          <View style={styles.separator} />

          <Row
            style={styles.gap}
            cols={[
              {
                label: '訂單編號',
                value: order?.id
              },
              {
                label: '訂單時間',
                value: order?.createdAt ? dayjs(order?.createdAt).format('YYYY-MM-DD HH:mm:ss') : null
              }
            ]}
          />

          <Row
            style={styles.gap}
            cols={[
              {
                label: '訂單狀態',
                value: order?.statusLabel
              },
              {
                label: '推薦人',
                value: order?.referrer?.fullname
              }
            ]}
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>購買資訊</Text>
          <View style={styles.separator} />

          <Row
            style={styles.gap}
            cols={[
              {
                label: '訂金',
                value: `$${order?.deposit}`
              },
              {
                label: '總價',
                value: `$${order?.price}`
              }
            ]}
          />

          <Row
            style={styles.gap}
            cols={[
              {
                label: '棟',
                value: order?.door?.floor?.building?.name
              },
              {
                label: '樓層',
                value: order?.door?.floor?.name
              }
            ]}
          />

          <Row
            style={styles.gap}
            cols={[
              {
                label: '戶',
                value: order?.door?.name
              }
            ]}
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>用戶資訊</Text>
          <View style={styles.separator} />

          <Row
            style={styles.gap}
            cols={[
              {
                label: '姓名',
                value: profile.fullname
              }, {
                label: 'EMAIL',
                value: profile.email
              }
            ]}
          />

          <Row
            style={styles.gap}
            cols={[
              {
                label: '電話',
                value: profile.telephone
              }
            ]}
          />
        </View>

        <View style={styles.section}>
          <Text style={styles.title}>付款資訊</Text>
          <View style={styles.separator} />

          {
            infoPairs.map((pairs) => (
              <Row
                key={`${pairs.join(',')}`}
                style={styles.gap}
                cols={pairs}
              />
            ))
          }
        </View>

      </Page>
    </Document>
  );
};

ReceiptPage.propTypes = {
  receipt: PropTypes.instanceOf(Receipt).isRequired,
  profile: PropTypes.object.isRequired
};

/**
 * /public/fonts/
 */
Font.register({
  family: 'custom', // custom name
  fontWeight: 'normal',
  fontStyle: 'normal',
  src: './fonts/SweiDevilLegCJKtc-Regular.ttf'
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 20
  },
  row: {
    flexDirection: 'row'
  },
  section: {
    margin: 10,
    flexDirection: 'column'
  },
  title: {
    fontSize: 16,
    fontFamily: 'custom'
  },
  separator: {
    height: 2,
    backgroundColor: '#7d6a51',
    margin: '10px 0 5px 0'
  },
  gap: {
    margin: '6px 0'
  }
});

export default ReceiptPage;
