import dayjs from 'dayjs';
import is from 'is_js';
import numeral from 'numeral';

import planJSON from 'src/json/plan.json';
import otherJson from 'src/json/other.json';
import { findIndexesByDoorId } from 'src/utils';

export const Status = {
  sold: 'sold',
  pending: 'pending',
  notSold: 'notSold',
  abandon: 'abandon',
  selecting: 'selecting',
  payment: 'payment'
};

export default class Model {
  constructor(params, buildings) {
    const {
      type,
      status,
      id,
      door,
      updatedAt,
      price,
      household,
      style,
      paymentType
    } = params; // from Order model, PreOrder model

    this.type = type;
    this.status = status;
    this.id = id;
    this.door = door;
    this.updatedAt = updatedAt;
    this.price = price;
    this.household = household;
    this.style = style;
    this.paymentType = paymentType;
    this.buildings = buildings;
    // 到 payment 需要 pid

    // 到 order 需要 oid

    // 到 preOrder 需要 pid
  }

  get orderType() {
    if (this.type === 'order') {
      return this.status;
    }

    if (this.type === 'preorder') {
      if (is.null(this.style) && is.null(this.paymentType)) return Status.selecting;
      return Status.payment;
    }

    return null;
  }

  get tag() {
    switch (this.orderType) {
      case Status.sold:
        return '賀成交';
      case Status.notSold:
        return '已售出';
      case Status.abandon:
        return '已退訂';
      case Status.pending:
        return '已下訂';
      case Status.selecting:
        return '選戶中';
      case Status.payment:
        return '付款中';
      default:
        return null;
    }
  }

  get updateTime() {
    const gap = dayjs().diff(this.updatedAt, 'day');
    const duration = dayjs().diff(this.updatedAt);
    if (gap < 1) {
      const hours = dayjs.duration(duration).hours();
      const mins = dayjs.duration(duration).minutes();
      if (hours === 0 && mins === 0) return dayjs(this.updatedAt).format('HH:mm');
      return `${hours > 0 ? `${hours}h` : ''} ${mins > 0 ? `${mins}m` : ''}`;
    }
    return dayjs(this.updatedAt).format('MM/DD/YYYY');
  }

  get totalCurrency() {
    if (this.type === 'preorder') {
      return `$ ${numeral(this.door?.price ?? 0).format()}`;
    }
    return `$ ${numeral(this.price ?? 0).format()}`;
  }

  get img() {
    return otherJson.order.finish;
  }

  get remarks() {
    const doorID = this.door.id;
    const [bIdx, fIdx, dIdx] = findIndexesByDoorId(this.buildings, doorID);
    const doorInfo = ((planJSON.buildings[bIdx]).floors[fIdx]).doors[dIdx];
    const remark1 = `${doorInfo.remark1}${doorInfo.remarkcontent1}${doorInfo.remarkunit1}`;
    const remark2 = `${doorInfo.remark2}${doorInfo.remarkcontent2}${doorInfo.remarkunit2}`;
    const remark3 = `${doorInfo.remark3}${doorInfo.remarkcontent3}${doorInfo.remarkunit3}`;
    return [remark1, remark2, remark3];
  }

  static fromRes(data, buildings) {
    return new Model(data, buildings);
  }
}
