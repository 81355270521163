import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import MediaQuery from 'react-responsive';

import withProfile from 'src/components/withProfile';
import ConversationClient from 'conversation-client';
import PropTypes from 'prop-types';
import QrCodeModal from '../CustomizeModal/QrCodeModal';
import AccountInfoModal from '../CustomizeModal/AccountInfoModal';

import styles from './styles.module.css';

@observer
class ConversationModule extends React.Component {

  componentDidUpdate() {
    const { profile } = this.props;

    if (!this.props.viewModel.client.current && profile) {
      this.props.viewModel.didUpdate(profile);
    }

    if (this.props.viewModel.client.current && !profile) {
      this.props.viewModel.end();
    }
  }

  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }

  render() {
    const { viewModel } = this.props;
    return (
      <>
        {
          viewModel.enable ? (
            <>
              {/* workaround for now: need to be fix using hook or other package */}
              <MediaQuery maxWidth={767} onChange={viewModel.handleChangeToSmall}>
                <>
                  {
                    !viewModel.isSmall && (<div>isSmall</div>)
                  }
                </>
              </MediaQuery>
              <div
                className={clsx(
                  styles.client,
                  viewModel.isSmall && styles.mobile,
                  !viewModel.visible && styles.minimal
                )}
              >
                <ConversationClient
                  ref={viewModel.client}
                  source="https://chat.maauu.com/core"
                  mobile={viewModel.isSmall}
                  visible={viewModel.visible}
                  token={viewModel.token}
                  groupId={viewModel.groupId}
                  onLoad={viewModel.onLoad}
                  onError={viewModel.onError}
                  onClose={viewModel.onClose}
                  onUnreadCountChanged={viewModel.onUnreadCountChanged}
                  onAnnouncementReceived={viewModel.onAnnouncementReceived}
                  onVideoCallIncoming={viewModel.onVideoCallIncoming}
                  onCartClicked={viewModel.onCartClicked}
                  onVoiceClicked={viewModel.onVoiceClicked}
                />
              </div>
              {
                viewModel.userIdentity === 'consumer'
                  ? (
                    <QrCodeModal
                      viewModel={viewModel.popupVm}
                    />
                  )
                  : null
              }
              {
                viewModel.userIdentity === 'sales'
                  ? (
                    <AccountInfoModal
                      viewModel={viewModel.popupVm}
                    />
                  )
                  : null
              }
            </>
          ) : null
        }
      </>
    );
  }
}

ConversationModule.propTypes = {
  viewModel: PropTypes.object,
  userIdentity: PropTypes.string,
  profile: PropTypes.object
};

ConversationModule.defaultProps = {
  viewModel: null,
  userIdentity: null,
  profile: null
};

export default withProfile(ConversationModule, false);
