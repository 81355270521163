import React from 'react';
import Button from 'src/components/Common/Button';

export default function Privacy(props) {
  return (
    <div>
      <p>
        本網站之服務旨在符合國家政策，協助建商或代銷透明物件資訊，協助消費者取得洽談權利；就房屋買賣、預約或付款等交易行為，皆非本網站的服務項目。
      </p>
      <p>
        雙方洽談權利是依消費者行使信用卡預授權功能而確認，該功能不論洽談結果，皆將在數日內取消，並依消費者自身發卡銀行作業標準，歸還額度。
      </p>
      {
        !props.isConfirm && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
            <Button onClick={props.onConfirm} style={{ background: '#416EDE', flexBasis: 384 }}>我同意</Button>
          </div>
        )
      }
    </div>
  );
}
