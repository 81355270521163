import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const Row = (props) => {
  const cols = props.cols ?? [];
  const width = 100 / (cols.length ?? 1);

  return (
    <View style={{ ...styles.row, ...(props.style ?? {}) }}>
      {
        cols.map((c) => (
          <View
            key={c.key ?? c.label}
            style={[
              styles.row,
              {
                width: `${width}%`
              }
            ]}
          >
            <Text style={styles.label}>{ `${c.label}: ` }</Text>
            <Text style={styles.value} wrap={false}>{ c.value ?? '' }</Text>
          </View>
        ))
      }
    </View>
  );
};

Row.propTypes = {
  style: PropTypes.object,
  cols: PropTypes.arrayOf(PropTypes.object).isRequired
};

Row.defaultProps = {
  style: null
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
  label: {
    fontSize: 12,
    fontFamily: 'custom'
  },
  value: {
    fontSize: 12,
    fontFamily: 'custom',
    marginLeft: 10,
    flex: 1
  }
});

export default Row;
