import numeral from 'numeral';
import is from 'is_js';

import Profile from './Profile';

export default class Order {
  constructor(params) {
    const {
      type,
      id,
      ownerId,
      door,
      doorId,
      status,
      price,
      deposit,
      ordererName,
      ordererInfo,
      paymentType,
      serialNumber,
      queueIndex,
      referrer,
      referrerId,
      style,
      remark,
      updatedAt,
      createdAt
    } = params;

    this.type = type;
    this.id = id;
    this.ownerId = ownerId;
    this.door = door;
    this.doorId = doorId;
    this.status = status;
    this.price = price;
    this.deposit = deposit;
    this.ordererName = ordererName;
    this.ordererInfo = ordererInfo;
    this.paymentType = paymentType;
    this.serialNumber = serialNumber;
    this.queueIndex = queueIndex;
    this.referrer = referrer ? Profile.fromRes(referrer) : null;
    this.referrerId = referrerId;
    this.style = style;
    this.remark = remark;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  get statusLabel() {
    switch (this.status) {
      case 'sold':
        return '成交';
      case 'notSold':
        return '無成交';
      case 'abandon':
        return '已退訂';
      case 'pending':
        return '已下訂';
      default:
        return this.status;
    }
  }

  get paymentTypeLabel() {
    switch (this.paymentType) {
      case 'direct':
        return '直接付款';
      case 'link':
        return '付款連結';
      default:
        return this.paymentType;
    }
  }

  get household() {
    try {
      return `${this.door.floor.building.name} ${this.door.floor.name} ${this.door.name}`;
    } catch (err) {
      return '';
    }
  }

  get priceLabel() {
    return is.existy(this.price) ? `NT$ ${numeral(this.price).format()}` : '';
  }

  static fromRes(data) {
    return new Order(data);
  }
}
