import React from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './style.module.css';

function Input(props) {
  const { name, control, defaultValue, rules, className, style, inputClassName, inputStyle, ...others } = props;
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules
  });
  return (
    <div className={clsx(styles.layout, className)} style={style}>
      <input className={clsx(styles.input, inputClassName)} style={inputStyle} {...field} {...others} />
    </div>
  );
}
Input.defaultProps = {
  name: '',
  control: null,
  defaultValue: '',
  rules: null,
  className: '',
  style: {},
  inputClassName: '',
  inputStyle: {}
};

Input.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.object,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  inputClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  style: PropTypes.object,
  inputStyle: PropTypes.object
};

export default Input;
