import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withProfile from 'src/components/withProfile';
import { withRouter } from 'react-router';

import { Link } from 'react-router-dom';

import styles from './styles.module.css';

@observer
class IconBtn extends React.Component {

  render() {
    const { iconBtnVm, openConversation } = this.props;
    const topPosition = this.props.height * iconBtnVm.yPercentage;
    const leftPosition = this.props.width * iconBtnVm.xPercentage;
    return (
      <>
        {
          this.props.iconBtnVm.targetPage?.page === 'customerService'
            ? (
              <div
                className={styles.outerContainer}
                style={{
                  position: 'absolute',
                  top: topPosition,
                  left: leftPosition
                }}
                onClick={this.props.profile ? openConversation : () => this.props.history.push('/signup', { target: '/home' })}
              >
                <div className={styles.icon}>
                  <img
                    className={styles.iconPic}
                    src={iconBtnVm.iconImg}
                    alt="icon"
                  />
                </div>
                <div
                  className={styles.iconText}
                >
                  {iconBtnVm.title}
                </div>
              </div>
            )
            : (
              <Link
                className={styles.outerContainer}
                style={{
                  position: 'absolute',
                  top: topPosition,
                  left: leftPosition
                }}
                to={{
                  pathname: iconBtnVm.targetPage.page === '/advertisement' ? '/display' : iconBtnVm.targetPage.page,
                  state: { slide: iconBtnVm.targetPage.slide }
                }}
              >
                <div className={styles.icon}>
                  <img
                    className={styles.iconPic}
                    src={iconBtnVm.iconImg}
                    alt="icon"
                  />
                </div>
                <div
                  className={styles.iconText}
                >
                  {iconBtnVm.title}
                </div>
              </Link>
            )
        }
      </>
    );
  }
}

IconBtn.propTypes = {
  iconBtnVm: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  openConversation: PropTypes.func,
  profile: PropTypes.object,
  history: PropTypes.object
};

IconBtn.defaultProps = {
  iconBtnVm: null,
  height: null,
  width: null,
  openConversation: null,
  profile: null,
  history: null
};

export default withProfile(withRouter(IconBtn), false);
