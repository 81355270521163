import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import withProfile from 'src/components/withProfile';

import ChooseSalesPopupViewModel from 'src/viewModels/ChooseSalesPopupViewModel';
import ChooseSalesPopup from '../ChooseSalesPopup';

import styles from './styles.module.css';
import serviceImg from '../../assets/service.png';
import salesChatIcon from '../../assets/salesChatIcon.png';
import preBtn from '../../assets/backArrow.png';

@observer
class NavBar extends React.Component {
  constructor() {
    super();

    this.chooseVm = new ChooseSalesPopupViewModel();
  }

  componentDidUpdate() {
    const { profile } = this.props;

    this.props.viewModel.updateProfile(profile);
    if (profile && this.chooseVm.salesList.length === 0) {
      this.chooseVm.didUpdate();
    }
  }

  render() {
    const { unread, openConversation, chatConversation, viewModel, isLoading } = this.props;
    const { isShow } = viewModel;
    return (
      <>
        {/* web */}
        <MediaQuery minWidth={768}>
          <div className={styles.navBar}>
            <Link
              to="/home"
            >
              <img src={viewModel.logoImg} alt="logo" className={styles.logoImg} />
            </Link>
            <div className={styles.rightContent}>
              {
                viewModel.profile?.group === 'sales'
                  ? (
                    <div
                      className={styles.serviceContainer}
                      onClick={openConversation}
                    >
                      <img src={salesChatIcon} alt="salesChat" />
                      <div
                        className={
                          clsx(styles.notification,
                            styles.salesNotification,
                            unread !== 0 ? styles.show : null)
                        }
                      />
                    </div>
                  )
                  : null
              }
              <Link to="/center" className={styles.toCenterContainer}>
                <img src={viewModel.memberImg} alt="member" className={styles.memberImg} />
                <div className={styles.name}>
                  {this.props.profile?.lastName + this.props.profile?.firstName || ''}
                </div>
              </Link>

            </div>
          </div>
        </MediaQuery>
        {/* mobile */}
        <MediaQuery maxWidth={767}>
          <div className={clsx(styles.navBar, !isShow ? styles.noShow : null)}>
            <div
              className={styles.leftContent}
              onClick={() => this.props.history.goBack()}
            >
              <img src={preBtn} alt="preBtn" className={styles.preBtn} />
            </div>
            <Link
              to="/home"
              className={styles.logoImg}
            >
              <img src={viewModel.logoImg} alt="logo" className={styles.logoImg} />
            </Link>
            <div className={styles.rightContent}>
              {
                viewModel.profile?.group === 'sales'
                  ? (
                    <div
                      className={styles.serviceContainer}
                      onClick={openConversation}
                    >
                      <img src={salesChatIcon} alt="salesChat" />
                      <div
                        className={
                          clsx(styles.notification,
                            styles.salesNotification,
                            unread !== 0 ? styles.show : null)
                        }
                      />
                    </div>
                  )
                  : (
                    <>
                      <div
                        className={styles.serviceContainer}
                        onClick={
                          viewModel.profile?.group === 'consumer'
                            ? this.chooseVm.combineShowModal
                            : () => this.props.history.push('/signup', { target: this.props.location.pathname })
                        }
                      >
                        <img src={serviceImg} alt="service" />
                        <div
                          className={
                            clsx(styles.notification,
                              styles.userNotification,
                              unread !== 0 ? styles.show : null)
                          }
                        />
                      </div>
                      <ChooseSalesPopup
                        viewModel={this.chooseVm}
                        openConversation={chatConversation}
                        isLoading={isLoading}
                      />
                    </>
                  )
              }
              <Link to="/center">
                <img src={viewModel.memberImg} alt="member" className={styles.memberImg} />
              </Link>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

NavBar.propTypes = {
  history: PropTypes.object,
  viewModel: PropTypes.object,
  openConversation: PropTypes.func,
  chatConversation: PropTypes.func,
  unread: PropTypes.number,
  profile: PropTypes.object,
  isLoading: PropTypes.bool,
  location: PropTypes.object
};

NavBar.defaultProps = {
  history: null,
  viewModel: null,
  openConversation: null,
  chatConversation: null,
  unread: null,
  profile: null,
  isLoading: true,
  location: null
};

export default withProfile(withRouter(NavBar), false);
