import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ScrollContainer from 'react-indiana-drag-scroll';
import LobbyViewModel from 'src/viewModels/Lobby';
import ShareBtn from 'src/components/ShareBtn';
import IconBtn from './components/iconBtn';

import styles from './styles.module.css';
import slideAlert from '../../assets/SlidesAlert.png';

@observer
class LobbyPage extends React.Component {
  constructor(props) {
    super(props);

    this.viewModel = new LobbyViewModel();
    this.openConversation = props.openConversation;
    this.chatConversation = props.chatConversation;
  }

  componentDidMount() {
    this.viewModel.didMount(this.props);
  }

  componentWillUnmount() {
    this.viewModel.willUnmount(this.props);
  }

  render() {
    const icons = this.viewModel.icons.map((icon, index) => (
      <IconBtn
      // TODO: change index.
        key={index}
        iconBtnVm={icon}
        width={this.viewModel.imgWidth}
        height={this.viewModel.imgHeight}
        openConversation={this.openConversation}
      />
    ));
    return (
      <ScrollContainer
        className={clsx('page', styles.pageContainer)}
        hideScrollbars={false}
      >
        <div className={styles.imgContainer}>
          <img
            src={this.viewModel.backgroundImg}
            style={{
              width: this.viewModel.imgWidth,
              height: this.viewModel.imgHeight
            }}
            className={styles.lobbyBg}
            alt="lobbyBg"
          />
          {icons}
        </div>
        <img
          className={styles.finger}
          src={slideAlert}
          alt="slideAlert"
        />
        <ShareBtn />
      </ScrollContainer>
    );
  }
}

LobbyPage.propTypes = {
  openConversation: PropTypes.func,
  chatConversation: PropTypes.func
};

LobbyPage.defaultProps = {
  openConversation: null,
  chatConversation: null
};

export default LobbyPage;
