import React from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Input from 'src/components/Common/Input';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import CommButton from 'src/components/Common/Button';
import { Button } from 'antd';
import styles from './styles.module.css';

export default function OtpForm({ seconds, onSubmit, onTimeUp, loading, btnText }) {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  return (
    <div>
      <fieldset>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className={styles.form_title}>
            已發送驗證碼至您的手機
            <span>※</span>
            <br />
            驗證碼將在
            {' '}
            <span className={styles.seconds}>
              {seconds}
              秒
            </span>
            {' '}
            後失效。
          </h3>
          <div className={clsx(styles.otp_layout)}>
            <div className={clsx(styles.otp_content)}>
              <Input
                control={control}
                name="otp"
                rules={{
                  validate: (value) => /\d{6}/.test(value)
                }}
                placeholder="請輸入驗證碼"
                className={[
                  styles.otp_input,
                  errors.phone && styles.error_bottom
                ]}
                maxLength={6}
              />
              <CommButton
                className={styles.otp_resend}
                disabled={seconds > 0}
                onClick={onTimeUp}
              >
                重新發送
              </CommButton>
            </div>
            <div style={{ position: 'relative', textAlign: 'left', marginTop: 4 }}>
              <ErrorMessage style={{ position: 'absolute' }} error={errors.otp} label="驗證碼格式錯誤，請輸入六位數字" />
            </div>
          </div>
          <div className={styles.submit}>
            <Button htmlType="submit" className={styles.login_btn} loading={loading}>
              {btnText}
            </Button>
          </div>
        </form>
      </fieldset>
    </div>
  );
}

OtpForm.propTypes = {
  seconds: PropTypes.number,
  onSubmit: PropTypes.func,
  onTimeUp: PropTypes.func,
  loading: PropTypes.bool,
  btnText: PropTypes.string
};

OtpForm.defaultProps = {
  seconds: 0,
  onSubmit: () => {},
  onTimeUp: () => {},
  loading: false,
  btnText: '驗證'
};
