/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import is from 'is_js';
import { Button } from 'antd';

import useTwZipCode from 'src/hooks/useTwZipCode';
import useBirth from 'src/hooks/useBirth';
import useJob from 'src/hooks/useJob';
import Checkbox from 'src/components/Common/Checkbox';
import Input from 'src/components/Common/Input';
import Radio from 'src/components/Common/Radio';
import PhoneInput from 'src/components/Common/PhoneInput';
import Select from 'src/components/Common/Select';
import ErrorMessage from 'src/components/Common/ErrorMessage';
import checkboxTrue from 'src/assets/checkbox_true.png';
import checkboxFalse from 'src/assets/checkbox_false.png';
import checkboxError from 'src/assets/checkbox_error.png';
import { phoneRule, convertTelephoneText } from 'src/constants';
import styles from './styles.module.css';
import common from '../../common.module.css';

function SignupForm({ onSubmit, onSignupRuleClick, isSignupAgree, onPrivacyClick, onCookieClick, loading, prePath }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({
    defaultValues: {
      gender: '1'
    }
  });
  const { yearOptions, monthOptions, dayOptions, onMonthChange } = useBirth();
  const { cityOptions, districtOptions, onCityChange } = useTwZipCode();
  const { jobOptions, onJobChange } = useJob();

  const genderRegistration = register('gender');
  const yearRegistration = register('year');
  const monthRegistration = register('month');
  const dayRegistration = register('day');
  const cityRegistration = register('city');
  const districtRegistration = register('region');
  const privacyRegistration = register('privacy', { required: true });
  const jobRegistration = register('job');
  const signupRegistration = register('signup', { required: true });

  const _convertBirth = (year, month, day) => {
    if (is.empty(year) || is.empty(month) || is.empty(day)) return null;
    const m = month < 10 ? `0${month}` : month;
    const d = day < 10 ? `0${day}` : day;
    const birth = `${year}${m}${d}`;
    return Number(birth);
  };
  // 沒填的內容送 null 郵遞區號 email 送 null
  const _onSubmit = (data) => {
    const req = {
      ...data,
      telephone: convertTelephoneText(data.telephone),
      gender: Number(data.gender),
      birthday: _convertBirth(data.year, data.month, data.day)
    };

    onSubmit(req);
  };

  useEffect(()=>{
    if(isSignupAgree) {
      setValue('signup',true)
    }
  }
  , [isSignupAgree])

  return (
    <div>
      <div className={common.content}>
        <fieldset className={common.form}>
          <h2 className={common.topic} style={{ marginBottom: 8 }}>晉身有殼一族</h2>
          <form onSubmit={handleSubmit(_onSubmit)}>
            <h3 className={common.form_title}>
              手機號碼
              <span>※</span>
              <br />
              <p className={common.form_subtitle}>這是登入帳號，為保護個人資料安全，綁定無法變更。</p>
            </h3>
            <div style={{ marginBottom: 9 }}>
              <PhoneInput
                control={control}
                name="telephone"
                rules={{
                  validate: (value) => phoneRule(value)
                }}
                placeholder="請輸入手機號碼"
                className={errors.telephone && common.error_bottom}
                maxLength={10}
              />
              <ErrorMessage error={errors.telephone} label="手機號碼格式錯誤" />
            </div>
            <h3 className={common.form_title} style={{ marginBottom: 7 }}>
              真實姓名
              <span>※</span>
            </h3>
            <div className={common.row} style={{ marginBottom: 12 }}>
              <div className={styles.name_item}>
                <Input
                  control={control}
                  name="lastName"
                  placeholder="姓"
                  rules={{ required: true }}
                  className={errors.lastName && common.error_bottom}
                />
                <ErrorMessage error={errors.lastName} label="姓名不能為空" />
              </div>
              <div className={styles.name_spacer} />
              <div className={styles.name_item}>
                <Input
                  control={control}
                  name="firstName"
                  placeholder="名"
                  rules={{ required: true }}
                  className={errors.firstName && common.error_bottom}
                />
                <ErrorMessage error={errors.firstName} label="姓名不能為空" />
              </div>
            </div>
            <h3 className={common.form_title} style={{ marginBottom: 5, fontSize: 12 }}>
              稱謂
              <span>※</span>
            </h3>
            <div className={common.row} style={{ marginBottom: 8 }}>
              <div className={styles.name_item}>
                <Radio
                  id="male"
                  value="1"
                  name="gender"
                  label="男"
                  style={{ marginRight: 20 }}
                  {...genderRegistration}
                />
                <Radio
                  id="female"
                  value="0"
                  name="gender"
                  label="女"
                  {...genderRegistration}
                />
              </div>
              <div className={styles.name_item}>
                <Select
                  className={styles.job}
                  options={jobOptions}
                  {...jobRegistration}
                  onChange={(e) => {
                    onJobChange(e.target.value);
                    jobRegistration.onChange(e);
                  }}
                />
              </div>
            </div>
            <h3 className={common.form_title} style={{ marginBottom: 0 }}>出生日期</h3>
            <div className={common.row} style={{ marginBottom: 5 }}>
              <div className={styles.year}>
                <Select options={yearOptions} {...yearRegistration} />
              </div>
              <div className={styles.day_spacer} />
              <div className={styles.month}>
                <Select
                  name="month"
                  options={monthOptions}
                  {...monthRegistration}
                  onChange={(e) => {
                    onMonthChange(e.target.value);
                    monthRegistration.onChange(e);
                  }}
                />
              </div>
              <div className={styles.day_spacer} />
              <div className={styles.day}>
                <Select options={dayOptions} {...dayRegistration} />
              </div>
            </div>
            <p className={common.form_subtitle}>會員應為法人或其所在國家地域法定成年人，始得註冊為本網站之會員</p>
            <h3 className={common.form_title} style={{ marginBottom: 0 }}>聯絡暨合約地址</h3>
            <div className={clsx(common.row, styles.contract_address)}>
              <div className={styles.city}>
                <Select
                  options={cityOptions}
                  {...cityRegistration}
                  onChange={(e) => {
                    onCityChange(e.target.value);
                    cityRegistration.onChange(e);
                  }}
                />
              </div>
              <div className={styles.add_spacer} />
              <div className={styles.district}>
                <Select options={districtOptions} {...districtRegistration} />
              </div>
              <div className={styles.add_spacer} />
              <div className={styles.postal_code}>
                <Input
                  control={control}
                  name="postalCode"
                  placeholder="郵遞區號"
                />
              </div>
            </div>
            <div className={styles.address}>
              <Input
                control={control}
                name="address"
                placeholder="街道, 巷弄, 門號, 樓層"
              />
            </div>
            <h3 className={common.form_title}>
              電子郵件
              <span>※</span>
              <br />
              <p className={common.form_subtitle}>敬請填寫正確，避免錯失會員權益，可能包含保留戶開賣、VIP優惠碼喔!</p>
            </h3>
            <div className={styles.email}>
              <Input
                control={control}
                name="email"
                placeholder="要正確喔"
                rules={{
                  validate: (value) => isEmail(value)
                }}
                className={errors.email && common.error_bottom}
              />
              <ErrorMessage error={errors.email} label="格式不正確" />
            </div>
            <div className={styles.privacy_layout}>
              <div className={styles.privacy}>
                <Checkbox id="privacy" {...privacyRegistration} className={errors.privacy && styles.checkbox_error} />
                <label htmlFor="privacy" className={styles.privacy_label}>
                  我同意
                  <span onClick={onPrivacyClick} className={styles.privacy_popup}>
                    隱私權保護政策
                  </span>
                  與
                  <span onClick={onCookieClick} className={styles.privacy_popup}>會員條款</span>
                </label>
              </div>
              <div className={styles.signup_rule_layout}>
                <Checkbox {...signupRegistration} className={styles.signup_checkbox} />
                {
                  isSignupAgree && <img src={checkboxTrue} width={20} height={20} style={{ marginRight: 10 }} />
                }
                {
                  !isSignupAgree && !errors.signup && <img src={checkboxFalse} width={20} height={20} style={{ marginRight: 10 }} />
                }

                {
                  !isSignupAgree && errors.signup && <img src={checkboxError}  width={20} height={20} style={{ marginRight: 10 }} />
                }
                <label className={styles.signup_label}>
                  本網站核心功能即將為您開啟，
                  <span onClick={onSignupRuleClick} className={styles.signup_popup}>請閱讀說明並註冊會員</span>
                  ，讓我們提供您最好的服務!
                </label>
              </div>
            </div>
            <div className={common.submit}>
              <Button className={styles.sign_up_button} htmlType="submit" loading={loading}>註冊</Button>
            </div>
            <div className={styles.login}>
              已經有帳號了？
              <Link
                to={{
                  pathname: '/login',
                  state: { target: prePath }
                }}
                style={{ fontWeight: 600, color: '#666666', textDecoration: 'underline' }}
              >
                登入
              </Link>
            </div>
          </form>
        </fieldset>
      </div>
    </div>
  );
}

SignupForm.defaultProps = {
  onSubmit: () => { },
  onPrivacyClick: () => { },
  onCookieClick: () => { },
  onSignupRuleClick: () => { },
  loading: false
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func,
  onPrivacyClick: PropTypes.func,
  onCookieClick: PropTypes.func,
  onSignupRuleClick: PropTypes.func,
  loading: PropTypes.bool
};

export default SignupForm;
